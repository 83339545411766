import {
  Avatar,
  Divider,
  Grid,
  Paper,
  Typography,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Autocomplete,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { BsFillPatchCheckFill } from "react-icons/bs";
import { MdEditSquare } from "react-icons/md";

import CustomInputField from "../../components/CustomInputField/Index";
import CustomInputAndSelectField from "../../components/CustomInputAndSelectField/Index";
import CustomPill from "../../components/CustomPill/Index";
import CustomTable from "../../components/CustomTable/Index";
import DashboardLayout from "../../layouts";
import offersStyles from "../../styles/pages/Offers/index.style";
import {
  OutlinedInput,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import CountryTable from "../../components/CountryTable/Index";
import { MdLogout } from "react-icons/md";
import { Password } from "@mui/icons-material";
import { ToastContainer, toast } from "react-toastify";
import AlarmIcon from "@mui/icons-material/Alarm";
import { MdLeaderboard } from "react-icons/md";
import { MdUpdate } from "react-icons/md";

import { slice } from "lodash";
import axios from "axios";
import CustomButton from "../../components/CustomButton/Index";

export default function Lead_details() {
  const navigate = useNavigate();
  const location = useLocation();
  const { updateId } = location.state || {};
  // const { updateId } = location.state || {};
  const classes = offersStyles();
  const token = localStorage.getItem("token");
  const [list, setList] = React.useState([]);
  const [mainlist, setMainList] = React.useState({});
  const [searchText, setSearchText] = React.useState("");
  const [state, setState] = React.useState({
    leadID: updateId,
    leadName: "",
    employeeName: [],
    followupTime: "",
    minuteBeforeReminder: "",
    reminderNote: "",
    membersID: [],
    members: "",
    leadStatusID: "",
    date: "",
    time: "",
    noteID: "",
    contactNumber: "",
    email: "",
    campaignID: "",
    campaignName: "",
    channelName: "",
    channelID: "",
    notes: "",
    StatusName: "",
    Business_Name: "",
    Contact_Person: "",
    address: "",
    mobileNo: "",
    WhatsAppNo: "",

    Business_type: "",
    Sector_Type: "",
    Sector_Type_id: null,
    Email: "",
    Name: "",
    District: "",
    City: "",
    Area: "",
    Phone_Number: "",
    Product_or_Service: "",
    agencies: "",
    lead_status: "",
    lead_statusId: null,
    business_details: "",
    gst_number: "",
    license_no: "",
    retailers: "",
    leadStatus: "",
    leadStatusId: null,
    isUpdate: false,
    others: "",
    licensetype: "",
    licensetype_id: null,
    leadSource: "",
    leadSource_id: null,
    id: null,
    isUpdate: false,
  });
  const [statelog, setStatelog] = React.useState({
    leadID: "",
    leadName: "",
    employeeName: "",
    time: "",
    date: "",
    noteID: "",
    leadStatusID: "",
    statusName: "",
    members: "",
    isUpdatelog: false,
  });
  console.log(statelog);
  const [statefollow, setStatefollow] = React.useState({
    leadID: "",
    leadName: "",
    employeeName: [],
    followupTime: "",
    minuteBeforeReminder: "",
    reminderDate: "",
    reminderNote: "",
    membersID: [],
    id: null,
    isUpdate: false,
  });
  console.log("folloestate", statefollow);

  console.log(state);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [leadName, setLeadName] = useState("");
  const [reminderNote, setReminderNote] = useState("");
  const [citylist, setcitylist] = React.useState([]);
  const [districtList, setdistrictList] = React.useState([]);
  const [sectypeList, setsectypeList] = React.useState([]);

  const [business_details, setbusiness_details] = React.useState([]);
  const [licenseList, setlicenseList] = React.useState([]);
  const [lead_SourceList, setlead_SourceList] = React.useState([]);
  const [lead_StatusList, setlead_StatusList] = React.useState([]);
  const useStyles = makeStyles((theme) => ({
    select: {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "#787878",
        },
        "&:hover fieldset": {
          borderColor: "#565656",
        },
        "&.Mui-focused fieldset": {
          borderColor: "#333333",
        },
      },
    },
    option: {
      '&[aria-selected="true"]': {
        backgroundColor: "#787878 !important",
        color: "black",
      },
    },
  }));

  const class2 = useStyles();

  const menuProps = {
    classes: { paper: class2.select },
    MenuListProps: {
      classes: { root: class2.option },
    },
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleleadlogClose = () => {
    setOpen(false);
  };

  // const handleCreateLead = () => {
  //   // Handle lead creation logic here
  //   console.log("Lead Name:", leadName);
  //   console.log("Reminder Note:", reminderNote);
  //   setOpen(false);
  // };

  const table = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }
      const data = { id: updateId };
      console.log(data);
      const requestOptions = {
        method: "POST",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}api/auth/getLeadDetailById`,
        requestOptions
      );
      const actualData = await res.json();

      console.log(actualData);
      setMainList(actualData.leadDetail || {}); // Ensure it's an array
    } catch (err) {
      console.log(err);
    }
  };
  const [Leadloglist, setLeadlogList] = React.useState([]);
  const table1 = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }
      const data = { leadID: updateId };
      console.log(data);
      const requestOptions = {
        method: "POST",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}api/auth/getAllLogByLeadID`,
        requestOptions
      );
      const actualData = await res.json();

      console.log(actualData);
      setLeadlogList(actualData.data || []); // Ensure it's an array
    } catch (err) {
      console.log(err);
    }
  };

  const [Followlist, setFollowlist] = React.useState([]);
  const table2 = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }
      const data = { leadID: updateId };
      console.log(data);
      const requestOptions = {
        method: "POST",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}api/auth/getReminderByLeadID`,
        requestOptions
      );
      const actualData = await res.json();

      console.log(actualData);
      setFollowlist(actualData.data || []); // Ensure it's an array
    } catch (err) {
      console.log(err);
    }
  };

  const [LeadStatusList, setLeadStatusList] = React.useState([]);
  const table3 = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}api/auth/getAllLeadStatuses`,
        requestOptions
      );
      const actualData = await res.json();

      console.log(actualData);
      setLeadStatusList(actualData.leadStatuses);
    } catch (err) {
      console.log(err);
    }
  };
  const [NoteList, setNoteList] = React.useState([]);
  const table4 = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}api/auth/getAllNotes`,
        requestOptions
      );
      const actualData = await res.json();

      console.log(actualData);
      setNoteList(actualData.data);
    } catch (err) {
      console.log(err);
    }
  };
  const [MemberList, setmemberList] = React.useState([]);
  const table5 = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}api/auth/getEmployeeDetails`,
        requestOptions
      );
      const actualData = await res.json();

      console.log(actualData);
      setmemberList(actualData?.employees);
    } catch (err) {
      console.log(err);
    }
  };
  console.log(MemberList);
  const [campList, setcampList] = React.useState([]);
  const table6 = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/api/auth/getAllCampaigns`,
        requestOptions
      );
      const actualData = await res.json();

      console.log(actualData);
      setcampList(actualData.data);
    } catch (err) {
      console.log(err);
    }
  };
  const [channelList, setChannelList] = React.useState([]);
  const table7 = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/api/auth/getAllChannels`,
        requestOptions
      );
      const actualData = await res.json();

      console.log(actualData);
      setChannelList(actualData?.data);
    } catch (err) {
      console.log(err);
    }
  };
  console.log("mainnnlistttt", mainlist);
  console.log("followlistttt", Followlist);
  console.log("membwerrwlistttt", MemberList);
  console.log("Channellist", channelList);
  console.log("lead", LeadStatusList);

  const lead_table = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}api/auth/getAllLeadStatuses`,
        requestOptions
      );
      const actualData = await res.json();

      console.log(actualData);
      setlead_StatusList(actualData.leadStatuses);
    } catch (err) {
      console.log(err);
    }
  };
  const lead_sourcetable = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}api/auth/getAllLeadSources`,
        requestOptions
      );
      const actualData = await res.json();

      console.log(actualData);
      setlead_SourceList(actualData.leadSources);
    } catch (err) {
      console.log(err);
    }
  };
  const license_table = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}api/auth/getAllLicenseTypes`,
        requestOptions
      );
      const actualData = await res.json();

      console.log(actualData);
      setlicenseList(actualData.licenseTypes);
    } catch (err) {
      console.log(err);
    }
  };

  const sector_table = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}api/auth/getAllSectorTypes`,
        requestOptions
      );
      const actualData = await res.json();

      console.log(actualData);
      setsectypeList(actualData.sectorTypes);
    } catch (err) {
      console.log(err);
    }
  };

  const city_table = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}api/auth/getAllCities`,
        requestOptions
      );
      const actualData = await res.json();

      console.log(actualData);
      setcitylist(actualData.cities);
    } catch (err) {
      console.log(err);
    }
  };

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        await table();
        await table1();
        await table2();
        await table3();
        await table4();
        await table5();
        await table6();
        await table7();
        await lead_table();
        await city_table();
        await lead_sourcetable();
        await license_table();
        await sector_table();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [updateId]);

  // React.useEffect(() => {
  //   if (updateId) {
  //     setState({
  //       isUpdate: true,
  //       id: updateId._id,
  //     });
  //   }
  // }, [updateId]);

  console.log(state);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedEmployee, setSelectedEmployee] = React.useState(null);
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleUpdate = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMenuClick = (event, employee) => {
    setAnchorEl(event.currentTarget);
    setSelectedEmployee(employee);
  };
  console.log(updateId);
  const [openleadDetailsDialog, setOpenLeadDetailsDialog] = useState(false);
  const handleOpenLeadDeatilsDialog = () => {
    setOpenLeadDetailsDialog(true);
  };

  const handleCloseLeadDetailsDialog = () => {
    setOpenLeadDetailsDialog(false);
  };

  const [openFollowUpDialog, setOpenFollowUpDialog] = useState(false);
  const handleOpenFollowUpDialog = () => {
    setOpenFollowUpDialog(true);
  };

  const handleCloseFollowUpDialog = () => {
    setOpenFollowUpDialog(false);
  };

  const handleCreateFollowUp = async () => {
    const convertTo12HourFormat = (time) => {
      let [hour, minute] = time.split(":");
      let period = "AM";

      if (hour > 12) {
        hour -= 12;
        period = "PM";
      } else if (hour == 12) {
        period = "PM";
      } else if (hour == 0) {
        hour = 12;
      }
      if (period === "PM") {
        if (hour < 10) {
          hour = "0" + hour;
        }
      }

      return `${hour}:${minute} ${period}`;
    };

    // Convert followupTime to 12-hour format with AM/PM
    const formattedTime = convertTo12HourFormat(statefollow.followupTime);

    // if (!validateFields()) return;
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const data = {
        leadID: updateId,
        leadName: statefollow.leadName,
        employeeName: statefollow.employeeName,
        followupTime: formattedTime,
        minuteBeforeReminder: statefollow.minuteBeforeReminder,
        reminderNote: statefollow.reminderNote,
        reminderDate: statefollow.reminderDate,
        membersID: statefollow.membersID,
      };

      const requestOptions = {
        method: "POST",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };

      const res = await fetch(
        `${process.env.REACT_APP_API_URL}api/auth/createFollowupReminder`,
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);

      if (actualData.status === 200) {
        table2();
        setStatefollow((prevState) => ({
          ...prevState,
          leadID: "",
          leadName: "",
          employeeName: [],
          followupTime: "",
          minuteBeforeReminder: "",
          reminderNote: "",
          membersID: [],
        }));
        handleCloseFollowUpDialog();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleCreateLeadlog = async () => {
    console.log(statelog);
    const convertTo12HourFormat = (time) => {
      if (!time) return ""; // Handle undefined or empty time
      let [hour, minute] = time.split(":");
      hour = parseInt(hour, 10);

      let period = "AM";
      if (hour > 12) {
        hour -= 12;
        period = "PM";
      } else if (hour === 12) {
        period = "PM";
      } else if (hour === 0) {
        hour = 12;
      }
      if (hour < 10) {
        hour = "0" + hour;
      }

      return `${hour}:${minute} ${period}`;
    };

    // Convert time to 12-hour format with AM/PM
    const formattedTime = statelog.time
      ? convertTo12HourFormat(statelog.time)
      : "";

    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const data = {
        leadID: updateId,
        leadName: statelog.leadName, // Fallback in case Leadloglist is empty
        logDetails: [
          {
            leadStatusID: statelog.leadStatusID,
            date: statelog.date,
            time: formattedTime,
            memberID: statelog.members,
            employeeName: statelog.employeeName, // Ensure memberID is included
            noteID: statelog.noteID,
          },
        ],
      };

      const requestOptions = {
        method: "POST",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };

      const res = await fetch(
        `${process.env.REACT_APP_API_URL}api/auth/createLeadLog`,
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);

      if (actualData.status === 200) {
        table1();
        setStatelog((prevState) => ({
          leadID: "",
          leadName: "",
          employeeName: "",
          time: "",
          date: "",
          noteID: "",
          leadStatusID: "",
          members: "", // Clear memberID
        }));
        handleleadlogClose();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleUpdateClick = (detail, log) => {
    handleClickOpen(); // Open the dialog
    console.log(detail);
    console.log(log);

    const convertTo24HourFormat = (time) => {
      const [timePart, modifier] = time.split(" ");
      let [hours, minutes] = timePart.split(":");
      if (hours === "12") {
        hours = "00";
      }
      if (modifier === "PM") {
        hours = parseInt(hours, 10) + 12;
      }
      return `${hours}:${minutes}`;
    };

    const formattedTime = convertTo24HourFormat(detail.time);
    console.log(formattedTime);
    setStatelog({
      ...statelog,
      id: log._id,
      leadID: log.leadID,
      leadName: detail.leadName,
      leadStatusID: detail.leadStatusID,
      statusName: detail.leadStatusName,
      date: detail.date.slice(0, 10),
      time: formattedTime,
      members: log.logCreatorID,
      employeeName: detail.creatorName,
      noteID: detail.noteID,
      noteDescription: detail.noteDescription,
      isUpdatelog: true,
    });
  };

  const handleUpdateLeadlog = async (id) => {
    const convertTo12HourFormat = (time) => {
      if (!time) return ""; // Handle undefined or empty time
      let [hour, minute] = time.split(":");
      hour = parseInt(hour, 10);

      let period = "AM";
      if (hour > 12) {
        hour -= 12;
        period = "PM";
      } else if (hour === 12) {
        period = "PM";
      } else if (hour === 0) {
        hour = 12;
      }
      if (hour < 10) {
        hour = "0" + hour;
      }

      return `${hour}:${minute} ${period}`;
    };

    // Convert time to 12-hour format with AM/PM
    const formattedTime = statelog.time
      ? convertTo12HourFormat(statelog.time)
      : "";

    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const data = {
        id: statelog.id,
        leadID: statelog.leadID,
        leadName: statelog.leadName,
        logDetails: [
          {
            leadStatusID: statelog.leadStatusID,
            date: statelog.date,
            time: formattedTime,
            memberID: statelog.members,
            employeeName: statelog.employeeName,
            noteID: statelog.noteID,
          },
        ],
      };

      console.log(Leadloglist);
      console.log(state);
      console.log(data);

      const requestOptions = {
        method: "PUT",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };

      console.log(requestOptions);

      const res = await fetch(
        `${process.env.REACT_APP_API_URL}api/auth/updateLeadLog`,
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);

      if (actualData.status === 200) {
        table1();
        setStatelog((prevState) => ({
          ...prevState,
          leadID: "",
          leadName: "",
          employeeName: "",
          time: "",
          date: "",
          noteID: "",
          leadStatusID: "",
          members: "", // Clear memberID
        }));
        handleleadlogClose();
      }
      setStatelog({
        isUpdatelog: false,
      });
    } catch (err) {
      console.log(err);
    }
  };

  React.useEffect(() => {
    console.log(mainlist);
    if (updateId && mainlist) {
      setState({
        leadName: mainlist?.leadName,
        contactNumber: mainlist?.contactNumber,
        email: mainlist?.email,
        campaignName: mainlist?.campaignID?.campaignName,
        campaignID: mainlist?.campaignID?.id,
        channelName: mainlist?.channelID?.channelName,
        channelID: mainlist?.channelID?.id,
        StatusName: mainlist?.leadStatusID?.StatusName,
        leadStatusID: mainlist?.leadStatusID?.id,
        notes: mainlist?.notes,
        Business_Name: mainlist?.businessName || "",
        Contact_Person: mainlist?.contactPerson || "",
        address: mainlist?.address || "",
        mobileNo: mainlist?.mobileNumber || "",
        WhatsAppNo: mainlist?.whatsappNumber || "",
        Industry_Type: mainlist?.industryType || "",
        Business_type: mainlist?.typeOfBusiness || "",
        Sector_Type: mainlist?.typeOfSector || "",
        Email: mainlist?.email || "",
        District: mainlist?.district || "",
        City: mainlist?.city || "",
        Area: mainlist?.area || "",
        Phone_Number: "", // Assuming this is different or not available in mainlist?
        Product_or_Service: mainlist?.offeringType || "",
        organizationType: mainlist?.organizationType || "",
        Remark: mainlist?.remark || "", // Assuming this is different or not available in mainlist?
        business_details: mainlist?.businessDetails || "",
        gst_number: mainlist?.gstNumber || "",
        license_no: mainlist?.licenseNumber || "",
        Lead_Name: "", // Assuming this is different or not available in mainlist?
        Lead_Id: "", // Assuming this is different or not available in mainlist?
        Employee_Name: "", // Assuming this is different or not available in mainlist?
        Employee_Id: "", // Assuming this is different or not available in mainlist?
        Description: "", // Assuming this is different or not available in mainlist?
        Billing_Address: "", // Assuming this is different or not available in mainlist?
        Shipping_Address: "", // Assuming this is different or not available in mainlist?
        Status: mainlist?.status || "",
        searchText: "", // Assuming this is different or not available in mainlist?

        others: mainlist?.othersDetails || "", // Assuming this is different or not available in mainlist?
        lead_status: mainlist?.leadStatus,
        licensetype: mainlist?.licenseTypeName,
        licensetype_id: mainlist?.licenseTypeID,
        leadSource: mainlist?.leadSourceName,
        leadSource_id: mainlist?.leadSourceID,
        Sector_Type_id: mainlist?.sectorTypeID || "",
        Sector_Type: mainlist?.sectorTypeName || "",
        leadStatusId: mainlist?.leadStatusID || "",
        leadStatus: mainlist?.leadStatusName || "",
        agencies: mainlist?.agencies || "",
        retailers: mainlist?.retailers || "",
        isUpdatelead: true,
        id: updateId._id,
      });
    }
  }, [mainlist]);

  console.log(state);

  const handleUpdateNewLead = async (id) => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const data = {
        id: updateId,
        leadName: state.leadName,
        contactNumber: state.contactNumber,
        email: state.email,
        campaignID: state.campaignID,
        channelID: state.channelID,
        notes: state.notes,
        leadStatusID: state.leadStatusID,
        statusName: state.StatusName,
      };

      // console.log(Leadloglist);
      console.log(state);
      console.log(data);

      const requestOptions = {
        method: "PUT",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };

      console.log(requestOptions);

      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/api/auth/updateLeadDetail`,
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);

      if (actualData.status === 200) {
        table();
        setState((prevState) => ({
          ...prevState,
          leadName: "",
          contactNumber: "",
          email: "",
          campaignID: "",
          campaignName: "",
          channelName: "",
          channelID: "",
          notes: "",
          StatusName: "",
          isUpdate: false, // Clear memberID
        }));
        handleCloseLeadDetailsDialog();
      }
      setState({
        isUpdate: false,
      });
    } catch (err) {
      console.log(err);
    }
  };
  const handleUpdateCustomer = (idx) => {
    if (
      state.Business_Name == "" ||
      state.Contact_Person == "" ||
      state.mobileNo == "" ||
      state.WhatsAppNo == "" ||
      state.Email == "" ||
      state.City == "" ||
      state.license_no == "" ||
      state.Sector_Type_id == null ||
      state.leadSource_id == null ||
      state.agencies == "" ||
      state.retailers == ""
    ) {
      toast.error("Fill all the information", {
        position: "top-center",
      });
    } else {
      const requestData = {
        id: mainlist._id,
        businessName: state.Business_Name,
        contactPerson: state.Contact_Person,
        address: state.address,
        mobileNumber: state.mobileNo,
        whatsappNumber: state.WhatsAppNo,
        email: state.Email,
        sectorTypeID: state.Sector_Type_id,
        leadSourceID: state.leadSource_id,
        licenseTypeID: state.licensetype_id,
        licenseNumber: state.license_no,
        gstNumber: state.gst_number,
        retailers: state.retailers,
        agencies: state.agencies,
        leadStatusID: state.leadStatusId,
        city: state.City,
      };

      axios
        .put(
          `${process.env.REACT_APP_BACKEND_URL}api/auth/updateLeadDetail`,
          requestData,
          {
            headers: {
              /* Your headers here */
              "Content-Type": "application/json", // Example header
              Authorization: `Bearer ${token}`, // Example authorization header
            },
          }
        )
        .then((response) => {
          if (response.data.status == 200) {
            // Assuming `table()` refreshes or updates the UI
            toast.success("Updated successfully!", {
              position: "top-center",
            });
            navigate("/NewLead_Details");
          } else {
            toast.error("Failed to save. Please try again.", {
              position: "top-center",
            });
          }
          setState({
            Business_Name: "",
            Contact_Person: "",
            address: "",
            mobileNo: "",
            WhatsAppNo: "",
            Industry_Type: "",
            Business_type: "",
            Sector_Type: "",
            Email: "",
            Name: "",
            District: "",
            City: "",
            Area: "",
            Phone_Number: "",
            Product_or_Service: "",
            organizationType: "",
            Remark: "",
            business_details: "",
            gst_number: "",
            license_no: "",
            Lead_Name: "",
            Lead_Id: "",
            Employee_Name: "",
            Employee_Id: "",
            Description: "",
            Billing_Address: "",
            Shipping_Address: "",
            Status: "",
            searchText: "",
            isUpdate: false,
          });
          // table();
          // setState({
          //   Business_Name: "",
          //   Name: "",
          //   Phone_Number: "",
          //   Email: "",
          //   Lead_Name: "",
          //   Employee_Name: "",
          //   Description: "",
          //   Billing_Address: "",
          //   Shipping_Address: "",
          //   Status: "",
          //   searchText: "",
          //   isUpdate: false,
          // });
          // navigate("/Customer_Details");
          handleCloseLeadDetailsDialog();
        })

        //   } else {
        //     toast.error("Failed to save. Please try again.", {
        //       position: "top-center",
        //     });
        //     toast.success("Create successfully!", {
        //       position: "top-center",
        //     });
        //   }
        // })
        .catch((error) => {
          console.error("Error fetching data:", error);
          toast.error("An error occurred. Please try again.", {
            position: "top-center",
          });
        });
    }
  };

  return (
    <div className={classes.container}>
      <DashboardLayout />
      <ToastContainer />
      <div className={classes.contentSection}>
        <div
          className={classes.contentHead}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Typography variant="h3" color={"primary"}>
              Leads Details
            </Typography>
          </div>
          <div
            onClick={() => {
              localStorage.clear();
              navigate("/");
            }}
            style={{ cursor: "pointer" }}
          >
            <Typography
              variant="h4"
              color="primary"
              style={{ display: "flex", alignItems: "center" }}
            >
              Log out
              <MdLogout size={30} style={{ marginLeft: "8px" }} />
            </Typography>
          </div>
        </div>
        <Divider className={classes.divider} />
        <div>
          <div
            className={classes.contentContainer}
            style={{ flexDirection: "row", display: "flex", gap: "20px" }}
          >
            {/* Lead Details */}
            <div style={{ flex: 1 }}>
              <Paper
                elevation={2}
                style={{ padding: "20px", marginBottom: "20px" }}
              >
                <Typography variant="h6" style={{ fontWeight: "bold" }}>
                  Lead Details
                </Typography>
                <div
                  style={{
                    display: "flex",
                    // flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: "26px",
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleOpenLeadDeatilsDialog}
                  >
                    Update
                  </Button>
                </div>
                <Grid
                  container
                  spacing={3}
                  direction="column"
                  style={{ marginTop: "-7px" }}
                >
                  <Grid item>
                    <Typography variant="h6" color="textSecondary">
                      <span style={{ fontWeight: "bold", marginRight: "20px" }}>
                        Business Name:
                      </span>
                      <span>{mainlist?.businessName || "N/A"}</span>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="h6" color="textSecondary">
                      <span style={{ fontWeight: "bold", marginRight: "20px" }}>
                        Contact Person:
                      </span>
                      <span>{mainlist?.contactPerson || "N/A"}</span>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="h6" color="textSecondary">
                      <span style={{ fontWeight: "bold", marginRight: "20px" }}>
                        Mobile Number:
                      </span>
                      <span>{mainlist?.mobileNumber || "N/A"}</span>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="h6" color="textSecondary">
                      <span style={{ fontWeight: "bold", marginRight: "20px" }}>
                        WhatsApp Number:
                      </span>
                      <span>{mainlist?.whatsappNumber || "N/A"}</span>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="h6" color="textSecondary">
                      <span style={{ fontWeight: "bold", marginRight: "20px" }}>
                        Email:
                      </span>
                      <span>{mainlist?.email || "N/A"}</span>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="h6" color="textSecondary">
                      <span style={{ fontWeight: "bold", marginRight: "20px" }}>
                        Address:
                      </span>
                      <span>{mainlist?.address || "N/A"}</span>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="h6" color="textSecondary">
                      <span style={{ fontWeight: "bold", marginRight: "20px" }}>
                        City:
                      </span>
                      <span>{mainlist?.city || "N/A"}</span>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="h6" color="textSecondary">
                      <span style={{ fontWeight: "bold", marginRight: "20px" }}>
                        Sector Type:
                      </span>
                      <span>{mainlist?.sectorTypeName || "N/A"}</span>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="h6" color="textSecondary">
                      <span style={{ fontWeight: "bold", marginRight: "20px" }}>
                        Lead Source:
                      </span>
                      <span>{mainlist?.leadSourceName || "N/A"}</span>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="h6" color="textSecondary">
                      <span style={{ fontWeight: "bold", marginRight: "20px" }}>
                        License Type:
                      </span>
                      <span>{mainlist?.licenseTypeName || "N/A"}</span>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="h6" color="textSecondary">
                      <span style={{ fontWeight: "bold", marginRight: "20px" }}>
                        License Number:
                      </span>
                      <span>{mainlist?.licenseNumber || "N/A"}</span>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="h6" color="textSecondary">
                      <span style={{ fontWeight: "bold", marginRight: "20px" }}>
                        GST Number:
                      </span>
                      <span>{mainlist?.gstNumber || "N/A"}</span>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="h6" color="textSecondary">
                      <span style={{ fontWeight: "bold", marginRight: "20px" }}>
                        Agencies:
                      </span>
                      <span>{mainlist?.agencies || "N/A"}</span>
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Typography variant="h6" color="textSecondary">
                      <span style={{ fontWeight: "bold", marginRight: "20px" }}>
                        Lead Status:
                      </span>
                      <span>{mainlist?.leadStatusName || "N/A"}</span>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="h6" color="textSecondary">
                      <span style={{ fontWeight: "bold", marginRight: "20px" }}>
                        Demo Scheduled For:
                      </span>
                      <span>
                        {mainlist?.demoScheduleDetails?.length > 0
                          ? mainlist?.demoScheduleDetails[0]?.employeeName
                          : "N/A"}
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="h6" color="textSecondary">
                      <span style={{ fontWeight: "bold", marginRight: "20px" }}>
                        Retailer:
                      </span>
                      <span>{mainlist?.retailers || "N/A"}</span>
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
              <Dialog
                open={openleadDetailsDialog}
                onClose={handleCloseLeadDetailsDialog}
              >
                <DialogTitle>Update Lead Details</DialogTitle>
                <DialogContent>
                  <Paper className={classes.paper}>
                    <Grid
                      container
                      spacing={3}
                      className="content"
                      alignItems={"end"}
                    >
                      {/* <Grid item xs={5}>
                <CustomInputAndSelectField
                  options={employeeList?.map((item) => item.personalDetails.employeeName)}
                  value={state.Employee_Name}
                  changeCallBack={(e, v, reason) => {
                    if (reason === "clear") {
                      setState({
                        ...state,
                        Employee_Name: "",   
                        Employee_Id: "",
                      })
                    } else {
  
                    console.log(v);
                    const selectedEmployee = employeeList.find(
                      (item) => item.personalDetails.employeeName === v
                    );
                    setState({
                      ...state,
                     Employee_Name: v,
                     Employee_Id: selectedEmployee._id?selectedEmployee._id:null,
                   })
                  }
                  }}
                  
                  label="Name"
                  fullWidth
                />
              
               </Grid> */}
                      {/* <Grid item xs={5} >
                    <CustomInputField
                      label="Employee Name"
                      value={state.employeeName}
                      onChange={(e) =>
                        setState({ ...state, employeeName: e.target.value })
                      }
                    />
                    {validationErrors.employeeName && (
                      <Typography color="error">
                        {validationErrors.employeeName}
                      </Typography>
                    )}
                  </Grid> */}
                      <Grid item xs={5}>
                        <CustomInputField
                          label="Business Name"
                          fullWidth
                          value={state.Business_Name}
                          onChange={(e) => {
                            const input = e.target.value;
                            const validInput = input.replace(
                              /[^a-zA-Z\s]/g,
                              ""
                            );
                            setState({
                              ...state,
                              Business_Name: validInput,
                            });
                          }}
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <CustomInputField
                          label="Contact Person Name"
                          fullWidth
                          value={state.Contact_Person}
                          onChange={(e) => {
                            const input = e.target.value;
                            const validInput = input.replace(
                              /[^a-zA-Z\s]/g,
                              ""
                            );
                            setState({
                              ...state,
                              Contact_Person: validInput,
                            });
                          }}
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <CustomInputField
                          label="Address"
                          fullWidth
                          value={state.address}
                          onChange={(e) => {
                            const input = e.target.value;

                            setState({
                              ...state,
                              address: input,
                            });
                          }}
                        />
                      </Grid>
                      {/* <Grid item xs={5}>
                <CustomInputField
                  label="Name"
                  fullWidth
                  value={state.Name}
                  onChange={(e) => {
                    const input = e.target.value;
                    const validInput = input.replace(/[^a-zA-Z\s]/g, "");
                    setState({
                      ...state,
                      Name: validInput,
                    });
                  }}
                />
              </Grid> */}
                      <Grid item xs={5}>
                        <CustomInputField
                          label="Mobile Number"
                          fullWidth
                          value={state.mobileNo}
                          onChange={(e) => {
                            const input = e.target.value;
                            const validInput = input
                              .replace(/[^0-9]/g, "")
                              .slice(0, 10);
                            setState({
                              ...state,
                              mobileNo: validInput,
                            });
                          }}
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <CustomInputField
                          label="WhatsApp Number"
                          fullWidth
                          value={state.WhatsAppNo}
                          onChange={(e) => {
                            const input = e.target.value;
                            const validInput = input
                              .replace(/[^0-9]/g, "")
                              .slice(0, 10);
                            setState({
                              ...state,
                              WhatsAppNo: validInput,
                            });
                          }}
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <CustomInputField
                          label="Email Id"
                          fullWidth
                          value={state.Email}
                          onChange={(e) =>
                            setState({
                              ...state,
                              Email: e.target.value,
                            })
                          }
                        />
                        {/* {validationErrors.email && (
                          <Typography color="error">
                            {validationErrors.email}
                          </Typography>
                        )} */}
                      </Grid>

                      <Grid item xs={5}>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <p
                            style={{
                              textAlign: "left",
                              fontWeight: "normal",
                              fontSize: "15px",
                            }}
                          >
                            Sector Type
                          </p>
                          <CustomInputAndSelectField
                            options={sectypeList?.map(
                              (item) => item.typeOfSector
                            )}
                            value={state.Sector_Type}
                            changeCallBack={(e, v) => {
                              const selected = sectypeList.find(
                                (item) => item.typeOfSector === v
                              );
                              setState((prevState) => ({
                                ...prevState,
                                Sector_Type: v,
                                Sector_Type_id: selected ? selected._id : "",
                              }));
                            }}
                            fullWidth
                          />
                        </div>
                      </Grid>

                      <Grid item xs={5}>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <p
                            style={{
                              textAlign: "left",
                              fontWeight: "normal",
                              fontSize: "15px",
                            }}
                          >
                            License Type
                          </p>
                          <CustomInputAndSelectField
                            options={licenseList?.map(
                              (item) => item.licenseTypeName
                            )}
                            value={state.licensetype}
                            changeCallBack={(e, v) => {
                              const selected = licenseList.find(
                                (item) => item.licenseTypeName === v
                              );

                              setState((prevState) => ({
                                ...prevState,
                                licensetype: v,
                                licensetype_id: selected ? selected._id : "",
                              }));
                            }}
                            fullWidth
                          />
                        </div>
                      </Grid>
                      <Grid item xs={5}>
                        <p>Lead Status</p>
                        <CustomInputAndSelectField
                          options={lead_StatusList?.map(
                            (item) => item.leadStatusName
                          )}
                          value={state.leadStatus}
                          changeCallBack={(e, v) => {
                            const selected = lead_StatusList.find(
                              (item) => item.leadStatusName === v
                            );
                            setState((prevState) => ({
                              ...prevState,
                              leadStatus: v,
                              leadStatusId: selected ? selected._id : "",
                            }));
                            // setSelectedSector(v);
                            // setSelectedSectorId(
                            //   selectedEntryType ? selectedEntryType._id : ""
                            // );
                          }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <p
                            style={{
                              textAlign: "left",
                              fontWeight: "normal",
                              fontSize: "15px",
                            }}
                          >
                            City
                          </p>
                          <CustomInputAndSelectField
                            options={citylist?.map((item) => item.cityName)}
                            value={state.City}
                            changeCallBack={(e, v) => {
                              setState({
                                ...state,
                                City: v,
                              });
                            }}
                            fullWidth
                          />
                        </div>
                      </Grid>
                      <Grid item xs={5}>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <p
                            style={{
                              textAlign: "left",
                              fontWeight: "normal",
                              fontSize: "15px",
                            }}
                          >
                            Lead Source
                          </p>
                          <CustomInputAndSelectField
                            options={lead_SourceList?.map(
                              (item) => item.leadSourceName
                            )}
                            value={state.leadSource}
                            changeCallBack={(e, v) => {
                              const selected = lead_SourceList.find(
                                (item) => item.leadSourceName === v
                              );

                              setState((prevState) => ({
                                ...prevState,
                                leadSource: v,
                                leadSource_id: selected ? selected._id : "",
                              }));
                            }}
                            fullWidth
                          />
                        </div>
                      </Grid>

                      {/* <Grid item xs={5}>
                <CustomInputAndSelectField
                  options={employeeList?.map(
                    (item) => item.personalDetails.employeeName
                  )}
                  value={state.Employee_Name}
                  changeCallBack={(e, v, reason) => {
                    if (reason === "clear") {
                      setState({
                        ...state,
                        Employee_Name: "",
                        Employee_Id: "",
                      });
                    } else {
                      console.log(v);
                      const selectedClient = employeeList.find(
                        (item) => item.personalDetails.employeeName === v
                      );
                      if (!state.Employee_Id.includes(selectedClient._id)) {
                        setState({
                          ...state,
                          Employee_Name: v,
                          Employee_Id: selectedClient._id,
                        });
                      }
                    }
                  }}
                  label="Client Creator"
                  fullWidth
                />
              </Grid> */}
                      <Grid item xs={5}>
                        <CustomInputField
                          label="Agencies"
                          fullWidth
                          value={state.agencies}
                          onChange={(e) =>
                            setState({
                              ...state,
                              agencies: e.target.value,
                            })
                          }
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <CustomInputField
                          label="Retailers"
                          fullWidth
                          value={state.retailers}
                          onChange={(e) =>
                            setState({
                              ...state,
                              retailers: e.target.value,
                            })
                          }
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <CustomInputField
                          label="License Number"
                          fullWidth
                          value={state.license_no}
                          onChange={(e) => {
                            const input = e.target.value;
                            setState({
                              ...state,
                              license_no: input,
                            });
                          }}
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <CustomInputField
                          label="GST Number"
                          fullWidth
                          value={state.gst_number}
                          onChange={(e) => {
                            const input = e.target.value;
                            setState({
                              ...state,
                              gst_number: input,
                            });
                          }}
                        />
                      </Grid>
                      {/* <Grid item xs={5}>
                <CustomInputAndSelectField
                  options={["Active", "Suspended"]}
                  value={state.Status}
                  changeCallBack={(e, v, reason) => {
                    if (reason === "clear") {
                      setState({
                        ...state,
                        Status: "",
                      });
                    } else {
                      setState({
                        ...state,
                        Status: v,
                      });
                    }
                  }}
                  label="Status"
                  fullWidth
                />
              </Grid>

              <Grid item xs={5}>
                <CustomInputField
                  label="Description"
                  fullWidth
                  value={state.Description}
                  onChange={(e) =>
                    setState({
                      ...state,
                      Description: e.target.value,
                    })
                  }
                />
              </Grid> */}
                    </Grid>
                    <ToastContainer />
                  </Paper>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={handleCloseLeadDetailsDialog}
                    color="primary"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={(e) => {
                      handleUpdateCustomer(state.id);
                    }}
                    color="primary"
                  >
                    Update
                  </Button>
                </DialogActions>
              </Dialog>
            </div>

            {/* Lead Log */}
            <div style={{ flex: 1 }}>
              <Paper
                elevation={2}
                style={{ padding: "20px", marginBottom: "20px" }}
              >
                <Typography
                  variant="h6"
                  style={{ marginBottom: "20px", fontWeight: "bold" }}
                >
                  Lead Log
                </Typography>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "20px",
                  }}
                >
                  {Leadloglist.length > 0 ? (
                    <>
                      {" "}
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() =>
                          handleUpdateClick(
                            Leadloglist[Leadloglist.length - 1].logDetails[
                              Leadloglist[Leadloglist.length - 1].logDetails
                                .length - 1
                            ],
                            Leadloglist[Leadloglist.length - 1]
                          )
                        }
                      >
                        Update
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleClickOpen}
                      >
                        Create
                      </Button>
                    </>
                  )}
                </div>
                <Grid container spacing={3} direction="column">
                  {Leadloglist.length > 0 ? (
                    Leadloglist.map((log, logIndex) => (
                      <React.Fragment key={logIndex}>
                        {log.logDetails.map((detail, detailIndex) => (
                          <React.Fragment key={detailIndex}>
                            <Grid item style={{ position: "relative" }}>
                              <div style={{ display: "flex" }}>
                                {/* Alarm Icon */}
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                  }}
                                >
                                  <MdLeaderboard
                                    style={{ color: "blue", zIndex: 1 }}
                                  />
                                  {/* Vertical line */}
                                  {detailIndex < Leadloglist.length - 1 && (
                                    <div
                                      style={{
                                        height: "8vw", // Adjust height as needed
                                        width: "2px",
                                        backgroundColor: "grey",
                                        marginTop: "6px",
                                        marginBottom: "1px",
                                      }}
                                    />
                                  )}
                                </div>
                                {/* Lead Status and other details */}
                                <div
                                  style={{
                                    marginLeft: "10px",
                                    alignItems: "start",
                                    flex: 1,
                                  }}
                                >
                                  <Typography
                                    variant="body1"
                                    color="textSecondary"
                                  >
                                    <span
                                      style={{
                                        fontWeight: "bold",
                                        marginRight: "10px",
                                      }}
                                    >
                                      Lead Status Name:
                                    </span>
                                    <span>
                                      {detail.leadStatusName || "N/A"}
                                    </span>
                                  </Typography>
                                  <Grid item>
                                    <Grid container spacing={1} direction="row">
                                      <Grid item>
                                        <Typography
                                          variant="body1"
                                          color="textSecondary"
                                        >
                                          <span
                                            style={{
                                              fontWeight: "bold",
                                              marginRight: "10px",
                                            }}
                                          >
                                            Date:
                                          </span>
                                          <span>
                                            {new Date(
                                              detail.date
                                            ).toLocaleDateString() || "N/A"}
                                          </span>
                                        </Typography>
                                      </Grid>
                                      <Grid item>
                                        <Typography
                                          variant="body1"
                                          color="textSecondary"
                                        >
                                          <span
                                            style={{
                                              fontWeight: "bold",
                                              marginRight: "10px",
                                            }}
                                          >
                                            Time:
                                          </span>
                                          <span>{detail.time || "N/A"}</span>
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid item>
                                    <Typography
                                      variant="body1"
                                      color="textSecondary"
                                    >
                                      <span
                                        style={{
                                          fontWeight: "bold",
                                          marginRight: "10px",
                                        }}
                                      >
                                        Employee Name:
                                      </span>
                                      <span>{detail.creatorName || "N/A"}</span>
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    <Typography
                                      variant="body1"
                                      color="textSecondary"
                                    >
                                      <span
                                        style={{
                                          fontWeight: "bold",
                                          marginRight: "20px",
                                        }}
                                      >
                                        Note Description:
                                      </span>
                                      <span>
                                        {detail.noteDescription == "Not Found"
                                          ? log.description || "N/A"
                                          : detail.noteDescription || "N/A"}
                                      </span>
                                    </Typography>
                                  </Grid>
                                </div>
                                {/* Update Icon */}
                                {/* <MdEditSquare
                                  style={{
                                    color: "blue",
                                    cursor: "pointer",
                                    marginLeft: "10px",
                                  }}
                                  onClick={() => handleUpdateClick(detail, log)}
                                /> */}
                              </div>
                            </Grid>
                          </React.Fragment>
                        ))}
                      </React.Fragment>
                    ))
                  ) : (
                    <Typography variant="body1" color="textSecondary">
                      No log details available.
                    </Typography>
                  )}
                </Grid>
                {/* lead log dialog */}
                <Dialog open={open} onClose={handleleadlogClose}>
                  <DialogTitle>Create Lead Log</DialogTitle>
                  <DialogContent>
                    <DialogContentText
                      style={{ gap: "5px", marginBottom: "15px" }}
                    >
                      To create a new lead Log, please enter all the information
                      below.
                    </DialogContentText>
                    <Grid item xs={6}>
                      <CustomInputField
                        label="Lead name"
                        fullWidth
                        value={mainlist?.contactPerson}
                        InputProps={{ readOnly: true }}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <CustomInputField
                        label="Date"
                        fullWidth
                        type="date"
                        value={statelog.date}
                        onChange={(e) =>
                          setStatelog({
                            ...statelog,
                            date: e.target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomInputField
                        label="Time"
                        fullWidth
                        type="time"
                        value={statelog.time}
                        onChange={(e) =>
                          setStatelog({
                            ...statelog,
                            time: e.target.value,
                          })
                        }
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <p
                        style={{
                          textAlign: "left",
                          fontWeight: "normal",
                          fontSize: "15px",
                        }}
                      >
                        Lead status
                      </p>

                      <CustomInputAndSelectField
                        options={LeadStatusList?.map(
                          (item) => item.leadStatusName
                        )}
                        value={statelog.statusName}
                        changeCallBack={(e, v) => {
                          const selectedDept = LeadStatusList.find(
                            (item) => item.leadStatusName === v
                          );
                          setStatelog({
                            ...statelog,
                            statusName: v,
                            leadStatusID: selectedDept._id
                              ? selectedDept._id
                              : null,
                          });
                        }}
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <p
                        style={{
                          textAlign: "left",
                          fontWeight: "normal",
                          fontSize: "15px",
                        }}
                      >
                        Employee Name
                      </p>

                      <CustomInputAndSelectField
                        options={MemberList?.map(
                          (item) => item.personalDetails.employeeName
                        )}
                        value={statelog.employeeName}
                        changeCallBack={(e, v) => {
                          const selectedEmp = MemberList.find(
                            (item) => item.personalDetails.employeeName === v
                          );
                          setStatelog({
                            ...statelog,
                            employeeName: v,
                            members: selectedEmp._id ? selectedEmp._id : null,
                          });
                        }}
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <p
                        style={{
                          textAlign: "left",
                          fontWeight: "normal",
                          fontSize: "15px",
                        }}
                      >
                        Notes
                      </p>

                      <CustomInputAndSelectField
                        options={NoteList?.map((item) => item.noteDescription)}
                        value={statelog.noteDescription}
                        changeCallBack={(e, v) => {
                          const selectedDept = NoteList.find(
                            (item) => item.noteDescription === v
                          );
                          setStatelog({
                            ...statelog,
                            noteDescription: v,
                            noteID: selectedDept._id ? selectedDept._id : null,
                          });
                        }}
                        fullWidth
                      />
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleleadlogClose} color="primary">
                      Cancel
                    </Button>

                    {statelog.isUpdatelog ? (
                      <Button
                        onClick={() => handleUpdateLeadlog(state.id)}
                        color="primary"
                      >
                        Update
                      </Button>
                    ) : (
                      <Button onClick={handleCreateLeadlog} color="primary">
                        Create
                      </Button>
                    )}
                  </DialogActions>
                </Dialog>
              </Paper>
            </div>
            {/* Lead followup */}
            <div style={{ flex: 1 }}>
              <Paper
                elevation={3}
                style={{ padding: "20px", marginBottom: "20px" }}
              >
                <Typography
                  variant="h6"
                  style={{ marginBottom: "20px", fontWeight: "bold" }}
                >
                  Lead followUp
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOpenFollowUpDialog}
                >
                  Create
                </Button>

                <Grid container spacing={1} direction="column" marginTop="15px">
                  {Followlist.map((followup, index) => (
                    <Grid item key={index} style={{ position: "relative" }}>
                      <div style={{ display: "flex", alignItems: "start" }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <AlarmIcon style={{ color: "blue", zIndex: 1 }} />
                          {index < Followlist.length - 1 && (
                            <div
                              style={{
                                // position: 'absolute',
                                // top: '40px',
                                // left: '18px', // Align with the center of the alarm icon
                                height: ["4vw"], // Adjust height as needed
                                width: "2px",
                                backgroundColor: "grey",
                                marginTop: "6px",
                                marginBottom: "1px",
                                // zIndex: 0,
                              }}
                            />
                          )}
                        </div>

                        <Typography
                          variant="h6"
                          color="textSecondary"
                          style={{ marginLeft: "10px" }}
                        >
                          {followup.reminderNote || "N/A"}
                        </Typography>
                      </div>
                    </Grid>
                  ))}
                </Grid>

                {/* Dialog for Lead Follow-Up */}
                <Dialog
                  open={openFollowUpDialog}
                  onClose={handleCloseFollowUpDialog}
                >
                  <DialogTitle>Create Lead Follow-Up</DialogTitle>
                  <DialogContent>
                    <DialogContentText
                      style={{ gap: "5px", marginBottom: "15px" }}
                    >
                      To create a new lead follow-up, please enter all the
                      information below.
                    </DialogContentText>
                    {/* Form fields for Lead Follow-Up */}
                    <Grid container spacing={2}>
                      {/* Your form fields here */}
                      {/* <p>Time</p> */}
                    </Grid>
                    <Grid item xs={6}>
                      <CustomInputField
                        label="Lead name"
                        fullWidth
                        value={mainlist?.contactPerson}
                        InputProps={{
                          readOnly: true,
                        }}
                        // onChange={(e) =>
                        //   setState({
                        //     ...state,
                        //     leadName: e.target.value,
                        //   })
                        // }
                      />
                    </Grid>

                    <Grid item xs={12} md={3}>
                      <InputLabel id="demo-simple-select-label">
                        Employee name
                      </InputLabel>
                      <Select
                        style={{
                          // padding: "10px",
                          borderColor: "rgba(148,158,171,0.32)",
                          boxShadow: "1px 3px 10px rgba(0,0,0,0.17)",
                          width: "100%",
                          borderRadius: "12px",
                        }}
                        labelId="demo-simple-select-label"
                        multiple
                        id="demo-simple-select"
                        value={statefollow.employeeName}
                        label="Employee name"
                        // onChange={handleChange}
                        input={<OutlinedInput label="Name" />}
                        fullWidth
                        onChange={(event) => {
                          const { value } = event.target;
                          console.log(value);
                          const selectedIds = value
                            .map((selectedValue) => {
                              const selectedItem = MemberList.find(
                                (item) =>
                                  item.personalDetails.employeeName ===
                                  selectedValue
                              );
                              return selectedItem ? selectedItem._id : null;
                            })
                            .filter(Boolean); // Filter out any null values
                          console.log(selectedIds);
                          setStatefollow({
                            ...statefollow,
                            employeeName: value,
                            membersID: selectedIds,
                          });
                        }}
                      >
                        {MemberList.map((item) => (
                          <MenuItem
                            key={item.id}
                            value={item.personalDetails.employeeName}
                            classes={{ root: class2.option }}
                          >
                            {item.personalDetails.employeeName}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>

                    <Grid item xs={5}>
                      <p style={{ marginTop: "20px" }}>Date</p>
                      <input
                        required=""
                        style={{
                          padding: "10px",
                          // borderColor: "rgba(148,158,171,0.32)",
                          boxShadow: "1px 3px 10px rgba(0,0,0,0.17)",
                          width: "100%",
                          borderRadius: "10px",
                          border: "none",
                          marginTop: "10px",
                        }}
                        onChange={(e) =>
                          setStatefollow({
                            ...statefollow,
                            reminderDate: e.target.value,
                          })
                        }
                        type="date"
                        min={new Date().toISOString().split("T")[0]}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <CustomInputField
                        label="Follow Up Time"
                        fullWidth
                        type="time"
                        value={statefollow.followupTime}
                        onChange={(e) =>
                          setStatefollow({
                            ...statefollow,
                            followupTime: e.target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomInputField
                        label="Minute Before Reminder"
                        fullWidth
                        value={statefollow.minuteBeforeReminder}
                        onChange={(e) =>
                          setStatefollow({
                            ...statefollow,
                            minuteBeforeReminder: parseInt(e.target.value),
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomInputField
                        label=" Reminder Note"
                        fullWidth
                        value={statefollow.reminderNote}
                        onChange={(e) =>
                          setStatefollow({
                            ...statefollow,
                            reminderNote: e.target.value,
                          })
                        }
                      />
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseFollowUpDialog} color="primary">
                      Cancel
                    </Button>
                    <Button onClick={handleCreateFollowUp} color="primary">
                      Create
                    </Button>
                  </DialogActions>
                </Dialog>
              </Paper>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { Modal, Box, Typography, Button, IconButton } from "@mui/material";
import { FaTimes } from "react-icons/fa";
import CustomInputAndSelectField from "../../components/CustomInputAndSelectField/Index"; // Adjust the path as necessary
import axios from "axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function Statuspop({
  open,
  handleClose,
  handleSave,
  selectedEmployee,
}) {
  const [sectorTypeList, setSectorTypeList] = useState([]);
  const [selectedSector, setSelectedSector] = useState("");
  const [selectedSectorId, setSelectedSectorId] = useState(null);
  const [lead_SourceList, setLeadSourceList] = useState([]);
  const token = localStorage.getItem("token");

  const table = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}api/auth/getAllLeadStatuses`,
        requestOptions
      );
      const actualData = await res.json();

      console.log(actualData);
      setLeadSourceList(actualData.leadStatuses);
    } catch (err) {
      console.log(err);
    }
  };
  console.log(selectedEmployee);
  useEffect(() => {
    table();

    const fetchSectorTypes = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}api/auth/getSectorTypes`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data && response.data.sectorTypes) {
          setSectorTypeList(response.data.sectorTypes);
        }
      } catch (error) {
        console.error("Error fetching sector types:", error);
      }
    };

    fetchSectorTypes();

    if (selectedEmployee) {
      setSelectedSector(selectedEmployee.leadStatusName || "");
      setSelectedSectorId(selectedEmployee.leadStatusID || null);
    }
  }, [token, selectedEmployee]);

  const handleCreateDpt = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }
      const data = {
        id: selectedEmployee._id,
        leadStatusID: selectedSectorId,
      };
      const requestOptions = {
        method: "PUT",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}api/auth/updateLeadStatus`,
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      if (actualData.status === 200) {
        handleSave();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleTransferSave = () => {
    handleCreateDpt();
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={style}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography id="modal-title" variant="h6" component="h2">
            Update Status
          </Typography>
          <IconButton onClick={handleClose}>
            <FaTimes />
          </IconButton>
        </div>
        <CustomInputAndSelectField
          options={lead_SourceList?.map((item) => item.leadStatusName)}
          value={selectedSector}
          changeCallBack={(e, v) => {
            const selectedEntryType = lead_SourceList.find(
              (item) => item.leadStatusName === v
            );
            setSelectedSector(v);
            setSelectedSectorId(selectedEntryType ? selectedEntryType._id : "");
          }}
          fullWidth
        />
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "10px",
            marginTop: "20px",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleTransferSave}
          >
            Save
          </Button>
          <Button variant="contained" color="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </div>
      </Box>
    </Modal>
  );
}

import React from "react";
import {
  Avatar,
  Divider,
  Typography,
  Grid,
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  IconButton,
} from "@mui/material";
import {
  FaEdit,
  FaTimes,
  FaUserFriends,
  FaEllipsisV,
  FaInfoCircle,
  FaExchangeAlt,
} from "react-icons/fa";
import { useLocation, useNavigate } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomInputField from "../../components/CustomInputField/Index";
import DashboardLayout from "../../layouts";
import countryListStyles from "../../styles/pages/CountryList/index.style";
import CountryTable from "../../components/CountryTable/Index";
import { FaMoneyCheckDollar } from "react-icons/fa6";
import AddServicePopup from "../../components/AddServicePopup/Index"; // Import the popup component
import ConfirmationDialog from "../../components/ConfirmationDialog/Index";

export default function ServiceList() {
  const classes = countryListStyles();
  const [mainlist, setMainList] = React.useState([
    {
      service_name: "abc",
      transfer_to: "abc",
      created_date: "2024-10-12",
      resolved_date: "2024-10-12",
      status: "abc",
    },
  ]);
  const [popupOpen, setPopupOpen] = React.useState(false); // State for controlling the popup
  const navigate = useNavigate();
  const [searchText, setSearchText] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [transferId, settransferId] = React.useState(null);
  const [statusId, setstatusId] = React.useState(null);
  const [updateservice, setupdateservice] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [selectedEmployee, setSelectedEmployee] = React.useState(null);
  const location = useLocation();
  const { updateId } = location.state || {};

  const handleMenuClick = (event, employee) => {
    setAnchorEl(event.currentTarget);
    setSelectedEmployee(employee);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    //setSelectedEmployee(null);
  };

  const handlePopupOpen = () => {
    setPopupOpen(true);
  };
  const handleCloseDialog = () => {
    setOpen(false);
    //  setDeleteId(null);
  };

  const handlePopupClose = () => {
    table();
    setupdateservice(null);
    settransferId(null);
    setstatusId(null);
    setPopupOpen(false);
  };

  const handlePopupSubmit = (newService) => {
    // Handle the new service submission here
    console.log(newService);
    // You can add the new service to the list or make an API call to save it
  };

  const table = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }
      const data = { customerID: updateId };
      const requestOptions = {
        method: "POST",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}api/auth/getServiceByCustomer`,
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      if (actualData.status == 200) {
        setMainList(actualData.services);
      } else {
        setMainList([]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  React.useEffect(() => {
    if (updateId) {
      table();
    }
  }, []);

  const handleServiceDelete = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }
      const data = { id: selectedEmployee._id };
      const requestOptions = {
        method: "DELETE",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}api/auth/deleteService`,
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      if (actualData.status == 200) {
        toast.success("Deleted Sucessfully", {
          position: "top-center",
        });
        table();
      } else {
        toast.error("Failed to delete", {
          position: "top-center",
        });
      }
      setOpen(false);
    } catch (err) {
      console.log(err);
      toast.error("An error occurred. Please try again.", {
        position: "top-center",
      });
    }
  };
  const handleDelete = () => {
    if (selectedEmployee && selectedEmployee.hasOwnProperty("_id")) {
      // handleNewLeadDelete(selectedEmployee._id);
      setOpen(true);
    }
    handleMenuClose();
  };

  return (
    <div className={classes.container}>
      <DashboardLayout />
      <ToastContainer />
      <div className={classes.contentSection}>
        <div
          className={classes.contentHead}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Typography variant="h3" color={"primary"}>
              Service List
            </Typography>
          </div>
          <div
            onClick={() => {
              localStorage.clear();
              navigate("/");
            }}
            style={{ cursor: "pointer" }}
          >
            <Typography variant="h4" color={"primary"}>
              <FaUserFriends /> Log Out
            </Typography>
          </div>
        </div>
        <Grid container spacing={4} className={classes.contentMain}>
          <Grid item xs={3}>
            <Button variant="contained" fullWidth onClick={handlePopupOpen}>
              Add Service
            </Button>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <div className={classes.contentMain}>
          <CustomInputField
            label="Search..."
            fullWidth
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            className="searchField"
          />
          <CountryTable
            fullWidth
            cols={[
              "Sl No.",
              "Service Name",
              "Remark",
              "Assigned To",
              "Created Date",
              "Resolved Date",

              "Status",
              "Action",
            ]}
            rows={[
              ...mainlist
                .filter((l) => {
                  const serviceName =
                    l.serviceName?.nameOfService?.toLowerCase() || "";
                  const remark = l.remark?.toLowerCase() || "";
                  // const transferTOID =
                  //   (l.transferTOID?.phoneNumber ?? "")
                  //     .toString()
                  //     .toLowerCase() || "";
                  const transferTOID =
                    l.transferTOID?.employeeName?.toLowerCase() || "";
                  const serviceStatus = l.serviceStatus?.toLowerCase() || "";

                  const searchLower = (searchText || "").toLowerCase();

                  return (
                    serviceName.includes(searchLower) ||
                    remark.includes(searchLower) ||
                    transferTOID.includes(searchLower) ||
                    serviceStatus.includes(searchLower) ||
                    searchLower === undefined
                  );
                })
                ?.map((vs, idx) => [
                  idx + 1,
                  vs?.serviceName?.nameOfService,
                  vs.remark ? vs.remark : "--",
                  vs.transferTOID?.employeeName,

                  vs.createdDate?.slice(0, 10),
                  vs.resolved_date === null
                    ? "--"
                    : vs.resolvedDate?.slice(0, 10),
                  vs.serviceStatus,
                  <span className={classes.actionBtn}>
                    <IconButton
                      onClick={(e) => handleMenuClick(e, vs)}
                      aria-controls={anchorEl ? "simple-menu" : undefined}
                      aria-haspopup="true"
                    >
                      <FaEllipsisV />
                    </IconButton>
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleMenuClose}
                    >
                      <MenuItem
                        onClick={() => {
                          setupdateservice(1);
                          handlePopupOpen();
                          handleMenuClose();
                        }}
                      >
                        <ListItemIcon>
                          <FaEdit />
                        </ListItemIcon>
                        <ListItemText primary="Edit" />
                      </MenuItem>

                      <MenuItem
                        onClick={() => {
                          settransferId(1);
                          handlePopupOpen();
                          handleMenuClose();
                        }}
                      >
                        <ListItemIcon>
                          <FaExchangeAlt />
                        </ListItemIcon>
                        <ListItemText primary="Update Transfer" />
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setstatusId(1);
                          handlePopupOpen();
                          handleMenuClose();
                        }}
                      >
                        <ListItemIcon>
                          <FaInfoCircle />
                        </ListItemIcon>
                        <ListItemText primary="Update Status" />
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          //
                          handleDelete();
                          handleMenuClose();
                        }}
                      >
                        <ListItemIcon>
                          <FaTimes />
                        </ListItemIcon>
                        <ListItemText primary="Remove" />
                      </MenuItem>
                    </Menu>
                  </span>,
                ]),
            ]}
          />
        </div>
        <AddServicePopup
          open={popupOpen}
          update={updateservice}
          transferId={transferId}
          statusId={statusId}
          selectedEmployee={selectedEmployee}
          handleClose={handlePopupClose}
          customerId={updateId}
          handleSubmit={handlePopupSubmit}
        />
      </div>
      <ConfirmationDialog
        open={open}
        onClose={handleCloseDialog}
        onConfirm={handleServiceDelete}
      />
    </div>
  );
}

import { Divider, Grid, Typography } from "@mui/material";
import React from "react";
import { AiFillHome } from "react-icons/ai";
import {
  FaArrowRight,
  FaHandshake,
  FaThumbtack,
  FaRupeeSign,
  FaPaperclip,
  FaPaperPlane,
  FaSignInAlt,
  FaUserFriends,
  FaPassport,
  FaUser,
  FaFileAlt,
  FaPhone,
  FaUserTie,
  FaUserPlus,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import DashboardLayout from "../../layouts";
import useStyles from "../../styles/pages/Dashboard/index.style";
import AccountCard from "./component/card";
import axios from "axios";

import DashboardCard from "./component/baseCard";
// import CustomDateRangePicker from "../../components/CustomDateRange";
import CustomDateRangePicker from "../../components/CustomDateRange";
import { MdLogout } from "react-icons/md";

export default function DashBoard() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [date, setDate] = React.useState({
    startDate: null,
    endDate: null,
  });
  const [mainlist, setMainList] = React.useState([]);
  const [mainNumlist, setMainNumList] = React.useState([]);
  const [serviced_customer, setServiced_customer] = React.useState([]);
  const token = localStorage.getItem("token");
  const [countState, setCountState] = React.useState({
    coupon: "N/A",
    payouts: "N/A",
  });

  const table = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}api/auth/getAllLeadStatusByLead`,
        requestOptions
      );
      const actualData = await res.json();

      console.log(actualData);
      setMainList(actualData.leadStatuses || []); // Ensure it's an array
    } catch (err) {
      console.log(err);
    }
  };
  const table2 = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}api/auth/getDashboardDetails`,
        requestOptions
      );
      const actualData = await res.json();

      console.log(actualData);
      setMainNumList(actualData); // Ensure it's an array
      const customerIDs = actualData.serviceDetails?.map((service) => ({
        customerID: service.customerID,
      }));
      setServiced_customer(customerIDs || []);
    } catch (err) {
      console.log(err);
    }
  };
  React.useEffect(() => {
    table();
    table2();
  }, []);
  return (
    <div className={classes.container}>
      <DashboardLayout />
      <div className={classes.contentSection}>
        <div
          className={classes.contentHead}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            {" "}
            <Typography variant="h3" color={"primary"}>
              <AiFillHome /> DashBoard
            </Typography>
          </div>

          <div
            onClick={() => {
              localStorage.clear();
              navigate("/");
            }}
            style={{ cursor: "pointer" }}
          >
            {" "}
            <Typography
              variant="h4"
              color="primary"
              style={{ display: "flex", alignItems: "center" }}
            >
              Log out
              <MdLogout size={30} style={{ marginLeft: "8px" }} />
            </Typography>
          </div>
        </div>

        <Divider className={classes.divider} />
        <div className={classes.contentMain}>
          {/* <Grid item xs={6}>
              <AccountCard
                title={"Payouts"}
                count={countState.payouts}
                color="info"
                Icon={<FaHandshake className={classes.icon} />}
                actionText={
                  <Typography display={"flex"} gap={1} alignItems="center">
                    View Detailed <FaArrowRight />
                  </Typography>
                }
                actionCallback={(e) => {
                  navigate("payouts");
                }}
              />
            </Grid> */}
          {/* <Grid item xs={6}>
              <AccountCard
                title={"Total Coupon"}
                Icon={<FaThumbtack className={classes.icon} />}
                count={countState.coupon}
                color="warning"
                actionText={
                  <Typography display={"flex"} gap={1} alignItems="center">
                    Create Coupon <FaArrowRight />
                  </Typography>
                }
                actionCallback={() => {
                  navigate("coupons");
                }}
              />
            </Grid> */}
          <Grid container spacing={3} style={{ marginTop: "8px" }}>
            <Grid
              item
              xs={4}
              onClick={(e) => {
                navigate("/Service_By_Month");
              }}
              sx={{ cursor: "pointer" }}
            >
              <DashboardCard
                title={"Total Services"}
                Icon={<FaUserTie className={classes.icon} />}
                count={mainNumlist.totalServices}
                color="box2"
              />
            </Grid>

            <Grid
              item
              xs={4}
              sx={{ cursor: "pointer" }}
              onClick={(e) => {
                navigate("/Customer_Details");
              }}
            >
              <DashboardCard
                title={"Total Customer"}
                Icon={<FaUser className={classes.icon} />}
                count={mainNumlist.totalCustomers}
                color="box3"
              />
            </Grid>
            <Grid
              item
              xs={4}
              sx={{ cursor: "pointer" }}
              onClick={(e) => {
                navigate("/Customer_Details", {
                  state: { assigned: serviced_customer },
                });
              }}
            >
              <DashboardCard
                title={"Total Assigned Services"}
                Icon={<FaUser className={classes.icon} />}
                count={mainNumlist.totalServices}
                color="box1"
              />
            </Grid>
            <Grid
              item
              xs={4}
              sx={{ cursor: "pointer" }}
              onClick={(e) => {
                navigate("/NewLead_Details");
              }}
            >
              <DashboardCard
                title={"Total Leads"}
                Icon={<FaUserPlus className={classes.icon} />}
                count={mainNumlist.totalLeads}
                color="box4"
              />
            </Grid>
            <Grid
              item
              xs={4}
              sx={{ cursor: "pointer" }}
              onClick={(e) => {
                navigate("/followup_tommorow");
              }}
            >
              <DashboardCard
                title={"Total leads to call tomorrow"}
                Icon={<FaPhone className={classes.icon} />}
                count={mainNumlist.totalFollowupRemindersForTomorrow}
                color="box2"
              />
            </Grid>
            <Grid
              item
              xs={4}
              sx={{ cursor: "pointer" }}
              onClick={(e) => {
                navigate("/followup_today");
              }}
            >
              <DashboardCard
                title={"Total leads to call today"}
                Icon={<FaPhone className={classes.icon} />}
                count={mainNumlist.totalFollowupRemindersForToday}
                color="box3"
              />
            </Grid>
          </Grid>

          <div>
            <p
              style={{ float: "left", marginTop: "10px", marginBottom: "20px" }}
            >
              Lead Numbers
            </p>
            <div style={{ display: "block", width: "100%", clear: "both" }}>
              <div
                style={{
                  display: "flex",
                  gap: "36px",
                  paddingTop: "8px",
                  marginTop: "20px",
                  flexWrap: "wrap", // Allows wrapping to new lines if there's not enough space
                }}
              >
                {mainlist.map((ls) => (
                  <div
                    key={ls.leadStatusName} // Ensure unique keys for mapped elements
                    style={{
                      flex: "0 0 18%", // Flex-grow: 0, Flex-shrink: 0, Flex-basis: 18%
                      minWidth: "200px", // Static minimum width for each card
                      boxSizing: "border-box", // Ensure padding/margins are included in width
                    }}
                  >
                    <DashboardCard
                      title={ls.leadStatusName}
                      Icon={<FaFileAlt className={classes.icon} />}
                      count={ls.totalNumber}
                      color="box4"
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* <Grid item xs={12} sm={12} md={12}>
            <CustomDateRangePicker date={date} setDate={setDate} />
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <RevenueChart date={date} />
          </Grid> */}
        </div>
      </div>
    </div>
  );
}

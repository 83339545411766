import React, { useState } from "react";
import CustomButton from "../../components/CustomButton/Index";
import CustomInputField from "../../components/CustomInputField/Index";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router";
import layoutStyles from "../../styles/layout/sidebar.boost.style";
import { Paper, Typography } from "@mui/material";
import axios from "axios";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
export default function LoginForm() {
  const navigate = useNavigate();
  const classes = layoutStyles();
  const [state, setState] = React.useState({
    email: "",
    password: "",
  });

  console.log(process.env.REACT_APP_API_URL);

  const login = async () => {
    if (state.email == "" || state.password == "") {
      toast.error("Provide email and password", {
        position: "top-center",
      });
    } else {
      try {
        const loginHeaders = new Headers();
        loginHeaders.append("Content-Type", "application/json");

        const data = {
          email: state.email,
          password: state.password,
        };
        const requestOptions = {
          method: "POST",
          headers: loginHeaders,
          body: JSON.stringify(data),
        };
        const res = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}api/auth/Adminlogin`,
          requestOptions
        );
        const actualData = await res.json();
        if (actualData.token) {
          // console.log(actualData.access);
          // console.log(actualData);
          localStorage.setItem("token", actualData.token);

          navigate("/dashboard");
        } else {
          toast.error("Invalid Email and password", {
            position: "top-center",
          });
        }
        console.log(actualData);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const [validationErrors, setValidationErrors] = useState({});

  const validateFields = () => {
    const errors = {};
    if (!state.email.endsWith("@gmail.com")) {
      toast.error("Email must end with @gmail.com", {
        position: "top-center",
      });
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  return (
    <Paper className={classes.layOutPaper}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <CustomInputField
            label="Email"
            fullWidth
            value={state.email}
            onChange={(e) =>
              setState({
                ...state,
                email: e.target.value,
              })
            }
            helperText="Enter your valid Email"
          />
          {validationErrors.email && (
            <Typography color="error">{validationErrors.email}</Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          <CustomInputField
            label="Password"
            type="password"
            fullWidth
            value={state.password}
            onChange={(e) =>
              setState({
                ...state,
                password: e.target.value,
              })
            }
          />
        </Grid>

        <Grid item xs={12}>
          <CustomButton
            text={"Sign In"}
            fullWidth
            variant="contained"
            onClick={login}
          />
        </Grid>
        <ToastContainer />
      </Grid>
    </Paper>
  );
}

import { Avatar, Divider, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { FaEdit, FaEye, FaTimes, FaUserFriends } from "react-icons/fa";
import { RiVisaFill } from "react-icons/ri";
import CustomButton from "../../components/CustomButton/Index";
import CustomInputField from "../../components/CustomInputField/Index";
import CustomPill from "../../components/CustomPill/Index";
import CustomTable from "../../components/CustomTable/Index";

import DashboardLayout from "../../layouts";
import offerStyles from "../../styles/pages/VisaCategory/index.style";
import axios from "axios";
import CustomTimeTable from "../../components/CustomTimeTable/Index";
import { useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import { MdLogout } from "react-icons/md";
import { Description, Notes, Title } from "@mui/icons-material";
import LeftSide from "./LeftSide";
import ConfirmationDialog from "../../components/ConfirmationDialog/Index";
export default function LogNote() {
  const classes = offerStyles();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const formData = new FormData();
  const [state, setState] = React.useState({
    Notes: "",
    id: "",
    searchText: "",
    isUpdate: false,
  });
  const [logNotesList, setLogNotesList] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState(null);
  function table() {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}api/auth/getAllNotes`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // Handle the response
        if (response.data.data) {
          setLogNotesList(response.data.data);
        }
        console.log(response.data.data);
      })
      .catch((error) => {
        // Handle errors
        console.error("Error fetching data:", error);
      });
  }
  React.useEffect(() => {
    table();
  }, []);

  const handleCreateLogNotes = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const token = localStorage.getItem("token");
      if (token) {
        loginHeaders.append("Authorization", `Bearer ${token}`);
      }
      const data = {
        noteDescription: state.Notes,
      };

      if (state.Notes == "") {
        toast.error("Fill all the information", {
          position: "top-center",
        });
      } else {
        const requestOptions = {
          method: "POST",
          headers: loginHeaders,
          body: JSON.stringify(data),
        };
        const res = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}api/auth/createNote`,
          requestOptions
        );

        const actualData = await res.json();
        console.log(actualData);
        // setVisaList(actualData.Country);
        if (actualData.status == 200) {
          table();
          setState({
            Notes: "",
            id: "",
            searchText: "",
            isUpdate: false,
          });

          toast.success("Created successfully!", {
            position: "top-center",
          });

          localStorage.removeItem("fee_icon");
        }
      }
    } catch (err) {
      console.log(err);
      toast.error("Failed to save. Please try again.", {
        position: "top-center",
      });
    }
  };

  const handleLogNotesDelete = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const Token = localStorage.getItem("token");
      if (Token) {
        loginHeaders.append("Authorization", `Bearer ${Token}`);
      }
      const data = { id: deleteId };
      const requestOptions = {
        method: "DELETE",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}api/auth/deleteNote`,
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      // setVisaList(actualData.Country);
      if (actualData.status == 200) {
        table();
        toast.success("Deleted successfully!", {
          position: "top-center",
        });
      } else {
        toast.error(actualData.message, {
          position: "top-center",
        });
      }
      setOpen(false);
    } catch (err) {
      console.log(err);
      toast.error("An error occurred. Please try again.", {
        position: "top-center",
      });
    }
  };
  const handleUpdateLogNotes = async (idx) => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const token = localStorage.getItem("token");
      if (token) {
        loginHeaders.append("Authorization", `Bearer ${token}`);
      }
      const data = {
        id: idx,
        noteDescription: state.Notes,
      };

      if (state.Notes == "" || idx == "") {
        toast.error("Fill all the information", {
          position: "top-center",
        });
      } else {
        const requestOptions = {
          method: "PUT",
          headers: loginHeaders,
          body: JSON.stringify(data),
        };
        const res = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}api/auth/updateNote`,
          requestOptions
        );

        const actualData = await res.json();
        console.log(actualData.data);
        // setVisaList(actualData.Country);
        if (actualData.status == 200) {
          table();
          setState({
            Notes: "",
            id: "",
            searchText: "",
            isUpdate: false,
          });
          toast.success("Updated successfully!", {
            position: "top-center",
          });

          localStorage.removeItem("updateId");
          localStorage.removeItem("fee_icon");
          // Navigate("/Department");
        }
      }
    } catch (err) {
      console.log(err);
      // toast.error("Failed to save. Please try again.", {
      //   position: "top-center",
      // });
    }

    console.log(idx);
  };
  const handleOpenDialog = (id) => {
    setDeleteId(id);

    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
    setDeleteId(null);
  };
  return (
    <div className={classes.container}>
      <DashboardLayout />
      <div className={classes.contentSection}>
        <div
          className={classes.contentHead}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Typography variant="h3" color={"primary"}>
              Log Notes
            </Typography>
          </div>
          <div
            onClick={() => {
              localStorage.clear();
              navigate("/");
            }}
            style={{ cursor: "pointer" }}
          >
            {" "}
            <Typography
              variant="h4"
              color="primary"
              style={{ display: "flex", alignItems: "center" }}
            >
              Log out
              <MdLogout size={30} style={{ marginLeft: "8px" }} />
            </Typography>
          </div>
        </div>
        <Divider className={classes.divider} />
        <div style={{ display: "flex", gap: "20px" }}>
          <LeftSide />

          <div>
            <div className={classes.contentMain} style={{ width: "62vw" }}>
              <Paper>
                <Grid
                  container
                  spacing={3}
                  className="content"
                  alignItems={"end"}
                >
                  <Grid item xs={5}>
                    <CustomInputField
                      label="Notes"
                      fullWidth
                      value={state.Notes}
                      onChange={(e) =>
                        setState({
                          ...state,
                          Notes: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  {/* <Grid item xs={5}>
                <CustomInputField
                  label="Description"
                  fullWidth
                  value={state.Description}
                  onChange={(e) =>
                    setState({
                      ...state,
                      Description: e.target.value,
                    })
                  }
                />

              </Grid> */}

                  {/* <Grid item xs={5}>
                <CustomInputField
                  type="File"
                  fullWidth
                  onChange={(e) =>
                    setState({
                      ...state,
                      visaImg: e.target.value,
                    })
                  }
                />
              </Grid> */}
                  <Grid item xs={2} alignItems="end">
                    {state.isUpdate ? (
                      <CustomButton
                        text={"Update"}
                        variant="contained"
                        color="warning"
                        fullWidth
                        onClick={(e) => {
                          handleUpdateLogNotes(state.id);
                        }}
                      />
                    ) : (
                      <CustomButton
                        text={"create"}
                        variant="contained"
                        fullWidth
                        onClick={(e) => {
                          handleCreateLogNotes();
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
                <ToastContainer />
              </Paper>

              <Paper style={{ marginTop: "20px" }}>
                <label
                  style={{
                    fontSize: "12px",
                    marginTop: "10px",
                    marginBottom: "-11px",
                    textAlign: "left",
                    display: "block",
                    paddingTop: "24px",
                  }}
                >
                  Search...
                </label>
                <CustomInputField
                  fullWidth
                  value={state.searchText}
                  onChange={(e) =>
                    setState({
                      ...state,
                      searchText: e.target.value,
                    })
                  }
                  className="searchField"
                />
                <div style={{ marginTop: "20px" }}>
                  <CustomTimeTable
                    fullWidth
                    cols={["Sl No.", "Notes Description", "Action"]}
                    rows={[
                      ...logNotesList
                        .filter((l) => {
                          const noteDescription =
                            l.noteDescription?.toLowerCase() || "";

                          const searchLower = (
                            state.searchText || ""
                          ).toLowerCase();

                          return (
                            noteDescription.includes(searchLower) ||
                            searchLower === undefined
                          );
                        })
                        ?.map((vs, idx) => [
                          idx + 1,
                          vs.noteDescription == null || vs.noteDescription == ""
                            ? "--"
                            : vs.noteDescription,

                          <span className={classes.actionBtn}>
                            <CustomButton
                              variant="contained"
                              color="error"
                              onClick={(e) => handleOpenDialog(vs._id)}
                              icon={<FaTimes />}
                            />
                            <CustomButton
                              variant="contained"
                              color="warning"
                              onClick={(e) =>
                                setState({
                                  Notes: vs.noteDescription,
                                  isUpdate: true,
                                  id: vs._id,
                                })
                              }
                              icon={<FaEdit />}
                            />
                          </span>,
                        ]),
                    ]}
                  />
                </div>
              </Paper>
            </div>
          </div>
        </div>
      </div>
      <ConfirmationDialog
        open={open}
        onClose={handleCloseDialog}
        onConfirm={handleLogNotesDelete}
      />
    </div>
  );
}

import React, { useState, useEffect } from "react";
import {
  Divider,
  Typography,
  Grid,
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Tabs,
  Tab,
  Box,
} from "@mui/material";
import {
  FaEdit,
  FaTimes,
  FaUserFriends,
  FaEllipsisV,
  FaCommentDots,
  FaExchangeAlt,
  FaInfoCircle,
  FaCalendarAlt,
} from "react-icons/fa";
import { useNavigate } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomInputField from "../../components/CustomInputField/Index";
import DashboardLayout from "../../layouts";
import countryListStyles from "../../styles/pages/CountryList/index.style";
import CustomTimeTable from "../../components/CustomTimeTable/Index";
import { MdLeaderboard } from "react-icons/md";
import axios from "axios";
import RemarkPopup from "../../components/RemarkPopup/Index";
import TransferPopup from "../../components/TransferPopup/Index";
import Statuspop from "../../components/Statuspop/Index";
import DemoSchedulePopup from "../../components/DemoSchedulePopup/Index";
import ConfirmationDialog from "../../components/ConfirmationDialog/Index";
import PageTable from "../../components/PageTable/Index";

export default function NewLead_Details() {
  const classes = countryListStyles();
  const token = localStorage.getItem("token");
  const [mainlist, setMainList] = useState([]);
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [allLength, setAllLength] = useState(0);
  const [openDemoSchedulePopup, setOpenDemoSchedulePopup] = useState(false);
  const [state, setState] = useState({
    Name: "",
    Phone_Number: "",
    Email: "",
    Campaign: "",
    Campaign_Id: "",
    Channel: "",
    Channel_Id: "",
    Lead_Status: "",
    Lead_Status_Id: "",
    Description: "",
    searchText: "",
    isUpdate: false,
  });
  const [newLeadList, setNewLeadList] = useState([]);
  const [openRemarkPopup, setOpenRemarkPopup] = useState(false);
  const [openTransferPopup, setOpenTransferPopup] = useState(false);
  const [openStatusPopup, setOpenStatusPopup] = useState(false);
  const [selectedTab, setSelectedTab] = useState("All");
  // const [selectedTabId, setSelectedTabId] = useState(0);
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [totalrecords, setTotalrecord] = React.useState(null);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleTable = async (newPage) => {
    // Update the page state first
    setPage(newPage);
  };
  function table(pg) {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}api/auth/getAllLeadDetails`,
        {
          // This is the request body. Add any necessary parameters here.
          pageNumber: pg,
          pageSize: rowsPerPage,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.leadDetails) {
          setNewLeadList(response.data.leadDetails);
          setAllLength(response.data.totalActiveLeads);
          setTotalrecord(response.data.totalActiveLeads);
        }
        console.log(response.data.leadDetails);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }
  const [lead_StatusList, setlead_StatusList] = React.useState([]);

  const table2 = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}api/auth/getAllLeadStatusByLead`,
        requestOptions
      );
      const actualData = await res.json();

      console.log(actualData);
      setlead_StatusList(actualData.leadStatuses);
      return actualData.leadStatuses;
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    table2();
    const fetch = async () => {
      if (selectedTab === "All") {
        table(page);
      } else {
        //  const statsList = await table2();
        //  console.log(statsList);

        const matchingStatus = lead_StatusList.find(
          (status) => status.leadStatusName === selectedTab
        );
        console.log(matchingStatus);
        if (matchingStatus) {
          filterData(matchingStatus.leadStatusID, matchingStatus.totalNumber);
        } else {
          console.log("No matching leadStatusName found.");
        }
      }
    };
    fetch();

    if (localStorage.getItem("createlead")) {
      toast.success("Created Sucessfully", {
        position: "top-center",
      });
      localStorage.removeItem("createlead");
    }
    if (localStorage.getItem("updatelead")) {
      toast.success("Update Sucessfully", {
        position: "top-center",
      });
      localStorage.removeItem("updatelead");
    }
  }, [page, rowsPerPage, selectedTab]);
  useEffect(() => {
    setPage(1);
  }, [selectedTab]);
  const handleNewLeadDelete = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      const Token = localStorage.getItem("token");
      if (Token) {
        loginHeaders.append("Authorization", `Bearer ${Token}`);
      }
      const data = { id: selectedEmployee._id };
      const requestOptions = {
        method: "DELETE",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}api/auth/deleteLeadDetail`,
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      if (actualData.status === 200) {
        table();
        table2();
        toast.success("Deleted successfully!", {
          position: "top-center",
        });
      }
      setOpen(false);
    } catch (err) {
      console.log(err);
      toast.error("An error occurred. Please try again.", {
        position: "top-center",
      });
    }
  };

  const handleMenuClick = (event, employee) => {
    setAnchorEl(event.currentTarget);
    setSelectedEmployee(employee);
    console.log(employee);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    if (selectedEmployee && selectedEmployee.hasOwnProperty("_id")) {
      // handleNewLeadDelete(selectedEmployee._id);
      setOpen(true);
    }
    handleMenuClose();
  };

  const handleUpdate = () => {
    if (selectedEmployee) {
      navigate("/NewLead", {
        state: { updateId: selectedEmployee },
      });
    }
    handleMenuClose();
  };

  const handleView = () => {
    if (selectedEmployee && selectedEmployee.hasOwnProperty("_id")) {
      navigate("/Lead_details", {
        state: { updateId: selectedEmployee._id },
      });
      console.log(selectedEmployee);
    }
    handleMenuClose();
  };

  const handleCloseDialog = () => {
    setOpen(false);
    //  setDeleteId(null);
  };
  const handleRemarkClick = () => {
    if (selectedEmployee) {
      console.log(selectedEmployee);
      setOpenRemarkPopup(true);
    }
    handleMenuClose();
  };

  const handleTransferClick = () => {
    if (selectedEmployee) {
      setOpenTransferPopup(true);
    }
    handleMenuClose();
  };

  const handleStatusClick = () => {
    if (selectedEmployee) {
      setOpenStatusPopup(true);
    }
    handleMenuClose();
  };

  const handleRemarkClose = () => {
    setOpenRemarkPopup(false);
  };

  const handleRemarkSave = () => {
    console.log(selectedTab);
    if (selectedTab === "All") {
      table(page);
    } else {
      const matchingStatus = lead_StatusList.find(
        (status) => status.leadStatusName === selectedTab
      );

      if (matchingStatus) {
        filterData(matchingStatus.leadStatusID);
      } else {
        console.log("No matching leadStatusName found.");
      }
    }
    toast.success("Remark Created Sucessfully", {
      position: "top-center",
    });
    handleRemarkClose();
  };

  const handleTransferClose = () => {
    setOpenTransferPopup(false);
  };

  const handleTransferSave = (sectorType) => {
    toast.success("Transfer Updated Sucessfully", {
      position: "top-center",
    });
    console.log("Transfer saved:", sectorType);
    handleTransferClose();
  };

  const handleStatusClose = () => {
    setOpenStatusPopup(false);
  };

  const handleStatusSave = (sectorType) => {
    console.log("Status saved:", sectorType);
    table();
    table2();
    toast.success("Status Updated Sucessfully", {
      position: "top-center",
    });
    handleStatusClose();
  };

  console.log(selectedEmployee);

  const handleTabChange = async (event, newValue) => {
    setSelectedTab(newValue);
    // setSelectedTabId()
  };
  const filterData = async (id, num) => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }
      const data = {
        leadStatusID: id,
        pageNumber: page,
        pageSize: rowsPerPage,
      };
      const requestOptions = {
        method: "POST",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}api/auth/getLeadByStatus`,
        requestOptions
      );
      const actualData = await res.json();

      console.log(actualData);
      setNewLeadList(actualData.leadDetails);
      setTotalrecord(num);
    } catch (err) {
      console.log(err);
    }
  };
  const handleDemoScheduleClick = () => {
    if (selectedEmployee) {
      setOpenDemoSchedulePopup(true);
    }
    handleMenuClose();
  };

  const handleDemoScheduleClose = () => {
    setOpenDemoSchedulePopup(false);
  };

  const handleDemoScheduleSave = (data) => {
    console.log("Demo Schedule Data:", data);
    // Add logic to save demo schedule data here
    toast.success("Demo Scheduled Successfully", {
      position: "top-center",
    });
    handleDemoScheduleClose();
  };
  return (
    <div className={classes.container}>
      <DashboardLayout />
      <ToastContainer />
      <div className={classes.contentSection}>
        <div
          className={classes.contentHead}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Typography variant="h3" color={"primary"}>
              New Lead Details
            </Typography>
          </div>
          <div
            onClick={() => {
              localStorage.clear();
              navigate("/");
            }}
            style={{ cursor: "pointer" }}
          >
            <Typography variant="h4" color={"primary"}>
              <FaUserFriends /> Log Out
            </Typography>
          </div>
        </div>
        <Grid container spacing={4} className={classes.contentMain}>
          <Grid item xs={3}>
            <Button
              variant="contained"
              fullWidth
              onClick={() => {
                navigate("/NewLead");
              }}
            >
              Add New Lead
            </Button>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />

        <div className={classes.contentMain}>
          <label
            style={{
              fontSize: "18px",
              marginTop: "10px",

              textAlign: "left",
              display: "block",
              paddingTop: "24px",
            }}
          >
            Search.....
          </label>
          <p
            style={{
              fontSize: "12px",
              color: "#7b7b7b",
              marginBottom: "-11px",
              textAlign: "left",
              display: "block",
              paddingTop: "8px",
            }}
          >
            By Business Name, Contact Person, Mobile Number, Email
          </p>
          <CustomInputField
            fullWidth
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            className="searchField"
          />
          <Box
            sx={{
              width: "100%",
              bgcolor: "background.paper",
              overflow: "visible",
            }}
            style={{ display: "flex", flexDirection: "row" }}
          >
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              aria-label="lead status tabs"
              variant="scrollable" // Use the scrollable variant
              scrollButtons="auto" // Show scroll buttons automatically when needed
              sx={{
                marginBottom: "10px",
                borderRadius: "10px",
              }}
            >
              <Tab
                key="All"
                label={`All (${allLength})`}
                onClick={(e) => {
                  filterData(0);
                }}
                value="All"
                style={{
                  border: "1px solid #e0e0e0",
                  backgroundColor:
                    selectedTab === "All" ? "#d8d9dc" : "inherit",
                  color: selectedTab === "All" ? "#27282c" : "inherit",
                }}
              />
              {[...lead_StatusList].reverse().map((ls) => {
                console.log(ls);
                return (
                  <Tab
                    key={ls.leadStatusName}
                    label={`${ls.leadStatusName} (${ls.totalNumber})`}
                    onClick={(e) => {
                      filterData(ls.leadStatusID, ls.totalNumber);
                    }}
                    value={ls.leadStatusName}
                    style={{
                      border: "1px solid #e0e0e0",
                      backgroundColor:
                        selectedTab === ls.leadStatusName
                          ? "#d8d9dc"
                          : "inherit",
                      color:
                        selectedTab === ls.leadStatusName
                          ? "#27282c"
                          : "inherit",
                    }}
                  />
                );
              })}
            </Tabs>
          </Box>

          <PageTable
            fullWidth
            cols={[
              "Sl No.",
              "Business Name",
              "Contact Person",
              "Phone Number",
              "Email",
              "Remarks",
              "Action",
            ]}
            rows={[
              ...newLeadList
                .filter((l) => {
                  const bus_name = l.businessName?.toLowerCase() || "";
                  const con_name = l.contactPerson?.toLowerCase() || "";
                  const mobile =
                    (l.mobileNumber ?? "").toString().toLowerCase() || "";
                  const email = l.email?.toLowerCase() || "";

                  const searchLower = (searchText || "").toLowerCase();

                  return (
                    bus_name.includes(searchLower) ||
                    con_name.includes(searchLower) ||
                    mobile.includes(searchLower) ||
                    email.includes(searchLower) ||
                    searchLower === undefined
                  );
                })
                ?.map((vs, idx) => [
                  idx + 1,
                  vs.businessName || "--",
                  vs.contactPerson || "--",
                  vs.mobileNumber || "--",
                  vs.email || "--",
                  <div>
                    {vs.remarkDetails?.map((remarkGroup, groupIdx) =>
                      remarkGroup?.map((remark, remarkIdx) => (
                        <div
                          key={`${groupIdx}-${remarkIdx}`}
                          style={{ marginTop: "8px" }}
                        >
                          <div>Date/Time: {remark.dateAndTime || "--"}</div>
                          <div>Name: {remark.memberName || "--"}</div>
                          <div>Remark: {remark.remark || "--"}</div>
                        </div>
                      ))
                    )}
                  </div>,
                  <span className={classes.actionBtn}>
                    <IconButton
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={(event) => handleMenuClick(event, vs)}
                    >
                      <FaEllipsisV />
                    </IconButton>
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleMenuClose}
                    >
                      <MenuItem onClick={handleUpdate}>
                        <ListItemIcon>
                          <FaEdit />
                        </ListItemIcon>
                        <ListItemText primary="Edit" />
                      </MenuItem>
                      <MenuItem onClick={handleRemarkClick}>
                        <ListItemIcon>
                          <FaCommentDots />
                        </ListItemIcon>
                        <ListItemText primary="Remark" />
                      </MenuItem>
                      <MenuItem onClick={handleTransferClick}>
                        <ListItemIcon>
                          <FaExchangeAlt />
                        </ListItemIcon>
                        <ListItemText primary="Transfer" />
                      </MenuItem>
                      <MenuItem onClick={handleStatusClick}>
                        <ListItemIcon>
                          <FaInfoCircle />
                        </ListItemIcon>
                        <ListItemText primary="Update Status" />
                      </MenuItem>
                      <MenuItem onClick={handleDemoScheduleClick}>
                        <ListItemIcon>
                          <FaCalendarAlt />
                        </ListItemIcon>
                        <ListItemText primary="Demo Schedule" />
                      </MenuItem>
                      <MenuItem onClick={handleView}>
                        <ListItemIcon>
                          <MdLeaderboard />
                        </ListItemIcon>
                        <ListItemText primary="Lead Details" />
                      </MenuItem>
                      <MenuItem onClick={handleDelete}>
                        <ListItemIcon>
                          <FaTimes />
                        </ListItemIcon>
                        <ListItemText primary="Delete" />
                      </MenuItem>
                    </Menu>
                  </span>,
                ]),
            ]}
            page={page}
            setPage={setPage} // Pass the handleTable function directly
            totalrecords={totalrecords}
            table={table}
            setRowsPerPage={setRowsPerPage}
            rowsPerPage={rowsPerPage}
          />
        </div>
      </div>
      <RemarkPopup
        open={openRemarkPopup}
        selectedEmployee={selectedEmployee}
        handleClose={handleRemarkClose}
        handleSave={handleRemarkSave}
      />
      <TransferPopup
        open={openTransferPopup}
        selectedEmployee={selectedEmployee}
        handleClose={handleTransferClose}
        handleSave={handleTransferSave}
      />
      <Statuspop
        open={openStatusPopup}
        selectedEmployee={selectedEmployee}
        handleClose={handleStatusClose}
        handleSave={handleStatusSave}
      />
      <DemoSchedulePopup
        open={openDemoSchedulePopup}
        selectedEmployee={selectedEmployee}
        handleClose={handleDemoScheduleClose}
        handleSave={handleDemoScheduleSave}
      />
      <ConfirmationDialog
        open={open}
        onClose={handleCloseDialog}
        onConfirm={handleNewLeadDelete}
      />
    </div>
  );
}

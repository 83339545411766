import React from "react";
import {
  Avatar,
  Divider,
  Typography,
  Grid,
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Box,
} from "@mui/material";
import {
  FaEdit,
  FaTimes,
  FaUserFriends,
  FaEllipsisV,
  FaExchangeAlt,
  FaInfoCircle,
} from "react-icons/fa";
import { useLocation, useNavigate } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomInputField from "../../components/CustomInputField/Index";
import DashboardLayout from "../../layouts";
import countryListStyles from "../../styles/pages/CountryList/index.style";
import CountryTable from "../../components/CountryTable/Index";
import { FaMoneyCheckDollar } from "react-icons/fa6";
import CustomTimeTable from "../../components/CustomTimeTable/Index";
import CustomButton from "../../components/CustomButton/Index";
import axios from "axios";
import { token } from "@mui/icons-material";
import PageTable from "../../components/PageTable/Index";
import ConfirmationDialog from "../../components/ConfirmationDialog/Index";
import { set } from "lodash";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import Papa from "papaparse";
import { BiSolidFileImport } from "react-icons/bi";
import { FaFileUpload } from "react-icons/fa";
import { FaFileDownload } from "react-icons/fa";
export default function Customer_Details() {
  const classes = countryListStyles();
  const token = localStorage.getItem("token");
  const [mainlist, setMainList] = React.useState([]);
  const navigate = useNavigate();
  const [searchText, setSearchText] = React.useState("");
  const [fileName, setName] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [opendialog, setOpenDialog] = React.useState(false);
  const [csvData, setCsvData] = React.useState([]);
  const [selectedEmployee, setSelectedEmployee] = React.useState(null);
  const [state, setState] = React.useState({
    Company_Name: "",
    Name: "",
    Phone_Number: "",
    Email: "",
    ClientFromLead: "",
    ClientFromLead_Id: "",
    Employee_Name: "",
    Employee_Id: "",
    Description: "",
    Billing_Address: "",
    Shipping_Address: "",
    Status: "",
    searchText: "",
    isUpdate: false,
  });
  const [page, setPage] = React.useState(1);
  const [open, setOpen] = React.useState(false);
  const [totalrecords, setTotalrecord] = React.useState(null);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [customerList, setCustomerList] = React.useState([]);
  const [idList, setIdList] = React.useState([]);
  const location = useLocation();
  const { assigned } = location.state || {};
  const handleMenuClick = (event, employee) => {
    setAnchorEl(event.currentTarget);
    setSelectedEmployee(employee);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  function table(pg) {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}api/auth/getAllCustomers`,
        {
          pageNumber: pg,
          pageSize: rowsPerPage,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        // Handle the response
        setCustomerList(response.data.customers);
        setTotalrecord(response.data.totalActiveCustomers);
      })
      .catch((error) => {
        // Handle errors
        console.error("Error fetching data:", error);
      });
  }
  React.useEffect(() => {
    table(page);
    if (localStorage.getItem("createCust")) {
      toast.success("Created Sucessfully", {
        position: "top-center",
      });
      localStorage.removeItem("createCust");
    }
    if (localStorage.getItem("updateCust")) {
      toast.success("Update Sucessfully", {
        position: "top-center",
      });
      localStorage.removeItem("updateCust");
    }
    console.log(page);
    if (assigned) {
      const assigned = location.state?.assigned || null; // Get assigned from location or set it to null if not provided

      const assignedCustomerIDs = assigned
        ? assigned.map((item) => item.customerID)
        : [];
      setIdList(assignedCustomerIDs);
    }
  }, [page, rowsPerPage]);

  const handleCustomerDelete = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const Token = localStorage.getItem("token");
      if (token) {
        loginHeaders.append("Authorization", `Bearer ${token}`);
      }
      const data = { id: selectedEmployee._id };
      const requestOptions = {
        method: "DELETE",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}api/auth/deleteCustomer`,
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      // setVisaList(actualData.Country);
      if (actualData.status == 200) {
        table();
        toast.success("Deleted successfully!", {
          position: "top-center",
        });
      }
      setOpen(false);
    } catch (err) {
      console.log(err);
      toast.error("An error occurred. Please try again.", {
        position: "top-center",
      });
    }
  };

  const handleDelete = (id) => {
    if (selectedEmployee && selectedEmployee.hasOwnProperty("_id")) {
      setOpen(true);
    }
    // handleNewLeadDelete(selectedEmployee._id);

    handleMenuClose();
  };
  const handleCloseDialog = () => {
    setOpen(false);
  };
  const handleDownload = () => {
    // Define the CSV file URL
    const csvFileUrl =
      "https://drive.google.com/uc?export=download&id=1VAlzqwSvuHLHQvFdZfk7gvpBD6yPEP0i"; // Replace with your actual CSV file URL

    // Create a temporary <a> element and trigger the download
    const link = document.createElement("a");
    link.href = csvFileUrl;
    link.setAttribute("download", "sample.csv"); // File name to be downloaded
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setName("");
    setOpenDialog(false);
  };
  const removeEmptyFields = (data) => {
    return data.filter((item) => {
      return (
        item.Business_Name &&
        item.Contact_Person &&
        item.Address &&
        item.MobileNo &&
        item.WhatsAppNo &&
        item.Email &&
        item.Industry_Type &&
        item.District &&
        item.City &&
        item.Area &&
        item.Product_or_Service &&
        item.Organization_Type &&
        item.License_No &&
        item.GST_Number &&
        item.Business_Details
      );
    });
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    setName(file.name);
    Papa.parse(file, {
      header: true, // To get key-value pairs
      complete: (result) => {
        setCsvData(result.data); // Set parsed data
      },
      error: (error) => {
        toast.error("Failed to read file.");
        console.error(error);
      },
    });
  };

  const handleUploadCustomers = async () => {
    const data = removeEmptyFields(csvData);
    let successCount = 0; // Counter for successful uploads
    const totalCustomers = data.length; // Total number of customers to upload

    for (const row of data) {
      const requestData = {
        firmName: row.Business_Name,
        contactPerson: row.Contact_Person,
        address: row.Address,
        mobileNumber: row.MobileNo,
        whatsappNumber: row.WhatsAppNo,
        email: row.Email,
        industryType: row.Industry_Type,
        district: row.District,
        city: row.City,
        area: row.Area,
        offeringType: row.Product_or_Service,
        organizationType: row.Organization_Type,
        licenseNumber: row.License_No,
        gstNumber: row.GST_Number,
        businessDetails: row.Business_Details,
        remark: row.Remark,
        accountantORca: row.Accountant_OR_CA,
        computer_hardware: row.Computer_Hardware,
        teamMember: row.Team_Member,
        existingSoftware: row.Existing_Software,
        antiVirus: row.Anti_Virus,
        billingPrinter: row.Billing_Printer,
        webSite: row.Website,
        digitalMarketing: row.Digital_Marketing,
      };

      if (requestData.industryType === "Sector Type") {
        requestData.typeOfSector = row.Sector_Type;
      } else if (requestData.industryType === "Business Type") {
        requestData.typeOfBusiness = row.Business_Type;
      }

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}api/auth/importCustomers`,
          requestData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data.status === 200) {
          successCount++; // Increment success counter
        } else {
          toast.error(`Failed to create customer ${row.Business_Name}.`);
        }
      } catch (error) {
        console.error(`Error creating customer ${row.Business_Name}:`, error);
        toast.error(`Error creating customer ${row.Business_Name}.`);
      }
    }

    // After processing all uploads, show the success popup
    if (successCount === totalCustomers) {
      toast.success(`All customers uploaded successfully!`);
      table(page);
    } else {
      toast.info(
        `Finished uploading customers. ${successCount} out of ${totalCustomers} created successfully.`
      );
    }
    handleClose();
  };

  return (
    <div className={classes.container}>
      <DashboardLayout />
      <ToastContainer />
      <div className={classes.contentSection}>
        <div
          className={classes.contentHead}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Typography variant="h3" color={"primary"}>
              New Customer Details
            </Typography>
          </div>
          <div
            onClick={() => {
              localStorage.clear();
              navigate("/");
            }}
            style={{ cursor: "pointer" }}
          >
            <Typography variant="h4" color={"primary"}>
              <FaUserFriends /> Log Out
            </Typography>
          </div>
        </div>
        <Grid container spacing={4} className={classes.contentMain}>

          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={3} sx={{paddingLeft: "36px"}}>
            <Button
              variant="contained"
              onClick={() => {
                navigate("/Customer");
              }}
            >
              Add New Customer
            </Button>
            </Grid>
            <Grid item xs={9}>
              <Box display="flex" justifyContent="flex-end">
                <Button variant="contained" onClick={handleDownload}>
                  CSV Sample Data <FaFileDownload size={20} style={{marginLeft: 8}} />
                </Button>
                <Button
                  variant="contained"
                  onClick={handleClickOpen}
                  style={{ marginLeft: 8 }} // Optional: Add some spacing between buttons
                >
                  Import Data <FaFileUpload size={20} style={{marginLeft: 8}} />
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <div className={classes.contentMain}>
          <CustomInputField
            label="Search..."
            fullWidth
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            className="searchField"
          />
          <PageTable
            fullWidth
            cols={[
              "Sl No.",
              "Business Name",
              "Contact Person",
              "Mobile Number",
              "License Number",
              "Business Type",
              "Action",
            ]}
            rows={[
              ...customerList
                .filter((customer) => {
                  if (assigned) {
                    // If assigned is present, filter based on customerID
                    return idList.includes(customer._id);
                  }
                  // If assigned is not provided, show all customers
                  return true;
                })
                .filter((l) => {
                  const firmName = l.firmName?.toLowerCase() || "";
                  const con_name = l.contactPerson?.toLowerCase() || "";
                  const mobileNumber =
                    (l.mobileNumber ?? "").toString().toLowerCase() || "";
                  const offeringType = l.licenseNumber?.toLowerCase() || "";
                  const organizationType =
                    l.typeOfBusiness?.toLowerCase() || "";

                  const searchLower = (searchText || "").toLowerCase();

                  return (
                    firmName.includes(searchLower) ||
                    con_name.includes(searchLower) ||
                    mobileNumber.includes(searchLower) ||
                    offeringType.includes(searchLower) ||
                    organizationType.includes(searchLower) ||
                    searchLower === undefined
                  );
                })
                ?.map((vs, idx) => [
                  idx + 1,
                  vs.firmName == null || vs.firmName == "" ? "--" : vs.firmName,

                  vs.contactPerson == null || vs.contactPerson == ""
                    ? "--"
                    : vs.contactPerson,

                  vs.mobileNumber == null || vs.mobileNumber == ""
                    ? "--"
                    : vs.mobileNumber,

                  vs.licenseNumber == null || vs.licenseNumber == ""
                    ? "--"
                    : vs.licenseNumber,

                  vs.typeOfBusiness == null || vs.typeOfBusiness == ""
                    ? "--"
                    : vs.typeOfBusiness,

                  <span className={classes.actionBtn}>
                    <IconButton
                      onClick={(e) => handleMenuClick(e, vs)}
                      aria-controls={anchorEl ? "simple-menu" : undefined}
                      aria-haspopup="true"
                    >
                      <FaEllipsisV />
                    </IconButton>
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleMenuClose}
                    >
                      <MenuItem
                        onClick={() => {
                          navigate("/Customer", {
                            state: { updateId: selectedEmployee },
                          });
                          handleMenuClose();
                        }}
                      >
                        <ListItemIcon>
                          <FaEdit />
                        </ListItemIcon>
                        <ListItemText primary="Edit" />
                      </MenuItem>

                      <MenuItem
                        onClick={() => {
                          navigate("/ServiceList", {
                            state: { updateId: selectedEmployee._id },
                          });
                          handleMenuClose();
                        }}
                      >
                        <ListItemIcon>
                          <FaExchangeAlt />
                        </ListItemIcon>
                        <ListItemText primary="Service List" />
                      </MenuItem>

                      <MenuItem
                        onClick={(e) => {
                          handleDelete();

                          handleMenuClose();
                        }}
                      >
                        <ListItemIcon>
                          <FaTimes />
                        </ListItemIcon>
                        <ListItemText primary="Remove" />
                      </MenuItem>
                    </Menu>
                  </span>,
                ]),
            ]}
            page={page}
            setPage={setPage} // Pass the handleTable function directly
            table={table}
            totalrecords={totalrecords}
            setRowsPerPage={setRowsPerPage}
            rowsPerPage={rowsPerPage}
          />
        </div>
      </div>
      <ConfirmationDialog
        open={open}
        onClose={handleCloseDialog}
        onConfirm={handleCustomerDelete}
      />

      <Dialog open={opendialog} onClose={handleClose} fullWidth>
        <DialogTitle><b>For Bulk Data Upload</b></DialogTitle>
        <DialogContent>
          <p>Upload File</p>
          <Box
            display="flex"
            alignItems="center"
            style={{
              padding: "4px",
              borderColor: "rgba(148,158,171,0.32)",
              boxShadow: "1px 3px 10px rgba(0,0,0,0.17)",
              width: "70%",
              borderRadius: "12px",
            }}
          >
            <Button
              variant="contained"
              component="label"
              style={{
                marginRight: "1rem",
                backgroundColor: "#f0f0f0",
                color: "#27282C",
                fontWeight: "500",
                boxShadow: "none",
              }}
            >
              Choose File
              <input
                type="file"
                hidden
                onChange={handleFileUpload}
                accept=".csv"
              />
            </Button>
            <span style={{ color: "#27282C", fontWeight: "500" }}>
              {fileName}
            </span>
          </Box>
        </DialogContent>
        <DialogActions>
         
          <Button onClick={handleClose}>Close</Button>
          <Button onClick={handleUploadCustomers}>Upload Customers</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Grid,
} from "@mui/material";
import CustomInputField from "../CustomInputField/Index";
import CustomInputAndSelectField from "../CustomInputAndSelectField/Index";

export default function AddServicePopup({
  open,
  handleClose,
  handleSubmit,
  customerId,
  update,
  selectedEmployee,
  transferId,
  statusId,
}) {
  const [serviceName, setServiceName] = React.useState("");
  const [state, setState] = React.useState({
    service_Name: "",
    service_NameId: "",
    transferTo: "",
    transferToId: "",
    remark: "",
    status: "",
    dueDate: "",
    id: null,
  });
  const [transferTo, setTransferTo] = React.useState("");
  const [mainlist, setMainList] = React.useState([]);
  const [serviceList, setserviceList] = React.useState([]);
  const table = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}api/auth/getEmployeeDetails`,
        requestOptions
      );
      const actualData = await res.json();
      setMainList(actualData.employees);
    } catch (err) {
      console.log(err);
    }
  };
  const table2 = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}api/auth/getAllServiceNames`,
        requestOptions
      );
      const actualData = await res.json();

      console.log(actualData);
      setserviceList(actualData.data);
    } catch (err) {
      console.log(err);
    }
  };
  React.useEffect(() => {
    table();
    table2();
    console.log(update);
    console.log(selectedEmployee);
    if (update == 1 || transferId == 1 || statusId == 1) {
      setState({
        service_Name: selectedEmployee.serviceName?.nameOfService,
        service_NameId: selectedEmployee.serviceName?.id,
        transferTo: selectedEmployee.transferTOID?.employeeName
          ? selectedEmployee.transferTOID.employeeName
          : selectedEmployee.serviceCreatorID.role === "EMPLOYEE"
          ? selectedEmployee.serviceCreatorID.creatorName
          : "",
        transferToId: selectedEmployee.transferTOID?.id
          ? selectedEmployee.transferTOID.id
          : selectedEmployee.serviceCreatorID.role === "EMPLOYEE"
          ? selectedEmployee.serviceCreatorID.id
          : "",
        id: selectedEmployee._id,
        remark: selectedEmployee.remark ? selectedEmployee.remark : "",
        status: selectedEmployee.serviceStatus,
        dueDate: selectedEmployee.dueDate?.slice(0, 10),
      });
    }
  }, [selectedEmployee, update, transferId, statusId]);
  console.log(update);
  console.log(selectedEmployee);
  console.log(state);
  const handleServiceNameChange = (event) => {
    setServiceName(event.target.value);
  };

  const handleTransferToChange = (event) => {
    setTransferTo(event.target.value);
  };

  const handleCreateService = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }
      const data = {
        serviceName: state.service_NameId,
        customerID: customerId,
        transferTOID: state.transferToId,
        remark: state.remark,
        dueDate: state.dueDate,
      };
      const requestOptions = {
        method: "POST",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}api/auth/createService`,
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      if (actualData.status == 200) {
        handleSubmit({ serviceName, transferTo });
        setState({
          service_Name: "",
          transferTo: "",
          transferToId: "",
          status: "",
          remark: "",
          id: null,
        });
        handleClose();
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleUpdateService = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }
      const data = {
        serviceName: state.service_NameId,
        customerID: customerId,
        transferTOID: state.transferToId,
        id: state.id,
        remark: state.remark,
        serviceStatus: state.status,
        dueDate: state.dueDate,
      };
      const requestOptions = {
        method: "PUT",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}api/auth/updateService`,
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      if (actualData.status == 200) {
        handleSubmit({ serviceName, transferTo });
        setState({
          service_Name: "",
          transferTo: "",
          transferToId: "",
          status: "",
          id: null,
          remark: "",
        });
        handleClose();
      }
    } catch (err) {
      console.log(err);
    }
  };
  //   const onSubmit = () => {
  //     handleSubmit({ serviceName, transferTo });
  //     handleClose();
  //   };
  console.log(state);
  return (
    <Dialog
      open={open}
      onClose={() => {
        setState({
          service_Name: "",
          transferTo: "",
          transferToId: "",
          status: "",
          remark: "",
          id: null,
        });
        handleClose();
      }}
    >
      <DialogTitle>Service</DialogTitle>
      <DialogContent>
        {statusId == 1 || transferId == 1 ? (
          <></>
        ) : (
          <Grid item xs={5} width={"32vw"}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "7px",
              }}
            >
              <p
                style={{
                  textAlign: "left",
                  fontWeight: "normal",
                  fontSize: "15px",
                }}
              >
                Service Name
              </p>
              <CustomInputAndSelectField
                options={serviceList?.map((item) => item.nameOfService)}
                value={state.service_Name}
                changeCallBack={(e, v) => {
                  const selected = serviceList.find(
                    (item) => item.nameOfService === v
                  );

                  setState((prevState) => ({
                    ...prevState,
                    service_Name: v,
                    service_NameId: selected._id,
                    //   Sector_Type_id: selected ? selected._id : "",
                  }));
                }}
                //   changeCallBack={(e, v) => {
                //     const selected = sectypeList.find(
                //       (item) => item.typeOfSector === v
                //     );

                //     setState((prevState) => ({
                //       ...prevState,
                //       Sector_Type: v,
                //       Sector_Type_id: selected ? selected._id : "",
                //     }));
                //   }}
                fullWidth
              />
              {/* {validationErrors.Sector_Type_id && (
              <Typography color="error">
                {validationErrors.Sector_Type_id}
              </Typography>
            )} */}
            </div>
            {/* {validationErrors.service_Name && (
            <Typography color="error">
              {validationErrors.service_Name}
            </Typography>
          )} */}
          </Grid>
        )}
        {statusId == 1 || transferId == 1 ? (
          <></>
        ) : (
          <>
            {" "}
            <Grid item xs={12} width={"32vw"}>
              <CustomInputField
                label="Remark"
                fullWidth
                value={state.remark}
                onChange={(e) => {
                  const input = e.target.value;

                  setState({
                    ...state,
                    remark: input,
                  });
                }}
              />
              {/* {validationErrors.service_Name && (
            <Typography color="error">
              {validationErrors.service_Name}
            </Typography>
          )} */}
            </Grid>
          </>
        )}
        {statusId == 1 ? (
          <></>
        ) : (
          <>
            <Grid item xs={5} width={"32vw"}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "7px",
                }}
              >
                <p
                  style={{
                    textAlign: "left",
                    fontWeight: "normal",
                    fontSize: "15px",
                  }}
                >
                  Assigned To
                </p>
                <CustomInputAndSelectField
                  options={mainlist?.map(
                    (item) => item.personalDetails.employeeName
                  )}
                  value={state.transferTo}
                  changeCallBack={(e, v) => {
                    const selected = mainlist.find(
                      (item) => item.personalDetails.employeeName === v
                    );

                    setState((prevState) => ({
                      ...prevState,
                      transferTo: v,
                      transferToId: selected._id,
                      //   Sector_Type_id: selected ? selected._id : "",
                    }));
                  }}
                  //   changeCallBack={(e, v) => {
                  //     const selected = sectypeList.find(
                  //       (item) => item.typeOfSector === v
                  //     );

                  //     setState((prevState) => ({
                  //       ...prevState,
                  //       Sector_Type: v,
                  //       Sector_Type_id: selected ? selected._id : "",
                  //     }));
                  //   }}
                  fullWidth
                />
                {/* {validationErrors.Sector_Type_id && (
              <Typography color="error">
                {validationErrors.Sector_Type_id}
              </Typography>
            )} */}
              </div>
            </Grid>
          </>
        )}
        {state.id !== null || statusId == 1 ? (
          transferId == 1 ? (
            <></>
          ) : (
            <>
              <Grid item xs={5}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "7px",
                  }}
                >
                  <p
                    style={{
                      textAlign: "left",
                      fontWeight: "normal",
                      fontSize: "15px",
                    }}
                  >
                    Status
                  </p>
                  <CustomInputAndSelectField
                    options={["New", "On Progress", "Resolved", "Cancelled"]}
                    value={state.status}
                    changeCallBack={(e, v) => {
                      setState((prevState) => ({
                        ...prevState,
                        status: v,
                        //   Sector_Type_id: selected ? selected._id : "",
                      }));
                    }}
                    //   changeCallBack={(e, v) => {
                    //     const selected = sectypeList.find(
                    //       (item) => item.typeOfSector === v
                    //     );

                    //     setState((prevState) => ({
                    //       ...prevState,
                    //       Sector_Type: v,
                    //       Sector_Type_id: selected ? selected._id : "",
                    //     }));
                    //   }}
                    fullWidth
                  />
                  {/* {validationErrors.Sector_Type_id && (
              <Typography color="error">
                {validationErrors.Sector_Type_id}
              </Typography>
            )} */}
                </div>
              </Grid>
            </>
          )
        ) : (
          <></>
        )}
        {statusId == 1 || transferId == 1 ? (
          <></>
        ) : (
          <>
            {" "}
            <Grid item xs={5}>
              <p style={{ marginTop: "20px" }}>Date</p>
              <input
                required=""
                style={{
                  padding: "10px",
                  // borderColor: "rgba(148,158,171,0.32)",
                  boxShadow: "1px 3px 10px rgba(0,0,0,0.17)",
                  width: "100%",
                  borderRadius: "10px",
                  border: "none",
                  marginTop: "10px",
                }}
                value={state.dueDate}
                onChange={(e) =>
                  setState((prevState) => ({
                    ...prevState,
                    dueDate: e.target.value,
                  }))
                }
                type="date"
                min={new Date().toISOString().split("T")[0]}
              />
            </Grid>{" "}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setState({
              service_Name: "",
              transferTo: "",
              transferToId: "",
              status: "",
              remark: "",
              id: null,
            });
            handleClose();
          }}
          color="primary"
        >
          Cancel
        </Button>
        {state.id !== null || statusId == 1 || transferId == 1 ? (
          <Button
            onClick={handleUpdateService}
            color="primary"
            variant="contained"
          >
            {/* {state.id?"Update":"Create"} */}
            Update
          </Button>
        ) : (
          <Button
            onClick={handleCreateService}
            color="primary"
            variant="contained"
          >
            {/* {state.id?"Update":"Create"} */}
            Create
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

import React from "react";
import {
  Avatar,
  Divider,
  Typography,
  Grid,
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  IconButton,
} from "@mui/material";
import { FaEdit, FaTimes, FaUserFriends, FaEllipsisV } from "react-icons/fa";
import { useNavigate } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomInputField from "../../components/CustomInputField/Index";
import DashboardLayout from "../../layouts";
import countryListStyles from "../../styles/pages/CountryList/index.style";
import CountryTable from "../../components/CountryTable/Index";
import { FaMoneyCheckDollar } from "react-icons/fa6";
import ConfirmationDialog from "../../components/ConfirmationDialog/Index";

export default function Employee_list() {
  const classes = countryListStyles();
  const [mainlist, setMainList] = React.useState([]);
  const navigate = useNavigate();
  const [searchText, setSearchText] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedEmployee, setSelectedEmployee] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const handleMenuClick = (event, employee) => {
    setAnchorEl(event.currentTarget);
    setSelectedEmployee(employee);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    //setSelectedEmployee(null);
  };

  const table = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}api/auth/getEmployeeDetails`,
        requestOptions
      );
      const actualData = await res.json();
      setMainList(actualData.employees);
    } catch (err) {
      console.log(err);
    }
  };

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        await table();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
    if (localStorage.getItem("createemp")) {
      toast.success("Created Sucessfully", {
        position: "top-center",
      });
      localStorage.removeItem("createemp");
    }
    if (localStorage.removeItem("updateemp")) {
      toast.success("Update Sucessfully", {
        position: "top-center",
      });
      localStorage.removeItem("updateemp");
    }
  }, []);

  const handleDelete = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }
      const data = { id: selectedEmployee._id };
      const requestOptions = {
        method: "DELETE",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}api/auth/deleteEmployee`,
        requestOptions
      );
      const actualData = await res.json();
      if (actualData.status == 200) {
        toast.success("Successfully deleted the employee details!");
        table();
      } else {
        toast.error("Failed to delete the employee details!");
      }
      setOpen(false);
    } catch (err) {
      console.log(err);
      toast.error("An error occurred. Please try again.");
    }
  };
  const handleDeletePop = () => {
    if (selectedEmployee && selectedEmployee.hasOwnProperty("_id")) {
      // handleNewLeadDelete(selectedEmployee._id);
      setOpen(true);
    }
    handleMenuClose();
  };
  const handleCloseDialog = () => {
    setOpen(false);
    //  setDeleteId(null);
  };

  return (
    <div className={classes.container}>
      <DashboardLayout />
      <ToastContainer />
      <div className={classes.contentSection}>
        <div
          className={classes.contentHead}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Typography variant="h3" color={"primary"}>
              Employee Details
            </Typography>
          </div>
          <div
            onClick={() => {
              localStorage.clear();
              navigate("/");
            }}
            style={{ cursor: "pointer" }}
          >
            <Typography variant="h4" color={"primary"}>
              <FaUserFriends /> Log Out
            </Typography>
          </div>
        </div>
        <Grid container spacing={4} className={classes.contentMain}>
          <Grid item xs={3}>
            <Button
              variant="contained"
              fullWidth
              onClick={() => {
                navigate("/Employee");
              }}
            >
              Add employees
            </Button>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <div className={classes.contentMain}>
          <CustomInputField
            label="Search..."
            fullWidth
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            className="searchField"
          />
          <CountryTable
            fullWidth
            cols={[
              "Sl No.",
              "Employee Name",
              "Photos",
              "Email",
              "Contact Number",
              "Department Name",
              "Desgination",
              "Action",
            ]}
            rows={[
              ...mainlist
                .filter((l) => {
                  const emp_name =
                    l.personalDetails.employeeName?.toLowerCase() || "";
                  const con_name = l.contactPerson?.toLowerCase() || "";
                  const mobile =
                    (l.personalDetails.phoneNumber ?? "")
                      .toString()
                      .toLowerCase() || "";
                  const email = l.personalDetails.email?.toLowerCase() || "";
                  const dept_name =
                    l.officialDetails.departmentName?.toLowerCase() || "";
                  const desgn_name =
                    l.officialDetails.designationName?.toLowerCase() || "";
                  const searchLower = (searchText || "").toLowerCase();

                  return (
                    emp_name.includes(searchLower) ||
                    con_name.includes(searchLower) ||
                    mobile.includes(searchLower) ||
                    email.includes(searchLower) ||
                    dept_name.includes(searchLower) ||
                    desgn_name.includes(searchLower) ||
                    searchLower === undefined
                  );
                })
                ?.map((vs, idx) => [
                  idx + 1,
                  vs.personalDetails.employeeName,
                  <Avatar
                    variant="rounded"
                    alt="VISA"
                    src={vs.documents.passportSizePhoto}
                    style={{
                      margin: "auto",
                    }}
                  />,
                  vs.personalDetails.email,
                  vs.personalDetails.phoneNumber,
                  vs.officialDetails.departmentName,
                  vs.officialDetails.designationName,
                  <span className={classes.actionBtn}>
                    <IconButton
                      onClick={(e) => handleMenuClick(e, vs)}
                      aria-controls={anchorEl ? "simple-menu" : undefined}
                      aria-haspopup="true"
                    >
                      <FaEllipsisV />
                    </IconButton>
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleMenuClose}
                    >
                      <MenuItem
                        onClick={() => {
                          navigate("/Employee", {
                            state: { updateId: selectedEmployee },
                          });
                          handleMenuClose();
                        }}
                      >
                        <ListItemIcon>
                          <FaEdit />
                        </ListItemIcon>
                        <ListItemText primary="Update" />
                      </MenuItem>

                      <MenuItem onClick={handleDeletePop}>
                        <ListItemIcon>
                          <FaTimes />
                        </ListItemIcon>
                        <ListItemText primary="Remove" />
                      </MenuItem>

                      <MenuItem
                        onClick={() => {
                          navigate("/Appriasal_list", {
                            state: { employeeID: selectedEmployee._id },
                          });
                          handleMenuClose();
                        }}
                      >
                        {/* <ListItemIcon>
                        <FaMoneyCheckDollar />
                      </ListItemIcon>
                      <ListItemText primary="Appraisal List" /> */}
                      </MenuItem>
                    </Menu>
                  </span>,
                ]),
            ]}
          />
        </div>
      </div>
      <ConfirmationDialog
        open={open}
        onClose={handleCloseDialog}
        onConfirm={handleDelete}
      />
    </div>
  );
}

import React from "react";
import {
  Avatar,
  Divider,
  Typography,
  Grid,
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  IconButton,
} from "@mui/material";
import { FaEdit, FaTimes, FaUserFriends, FaEllipsisV } from "react-icons/fa";
import { useNavigate } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomInputField from "../../components/CustomInputField/Index";
import DashboardLayout from "../../layouts";
import countryListStyles from "../../styles/pages/CountryList/index.style";
import CustomTimeTable from "../../components/CustomTimeTable/Index";
import CustomButton from "../../components/CustomButton/Index";
import axios from "axios";
import CustomDateRangePicker from "../../components/CustomDateRange";
import * as XLSX from "xlsx";
import { FaFileExcel } from "react-icons/fa";

export default function Customer_By_Month() {
  const classes = countryListStyles();
  const token = localStorage.getItem("token");
  const [customerList, setCustomerList] = React.useState([]);
  const [empId, setempId] = React.useState(null);
  const [searchText, setSearchText] = React.useState("");
  const [date, setDate] = React.useState({
    startDate: null,
    endDate: null,
  });
  const navigate = useNavigate();

  function table() {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}api/auth/getAllCustomers`,
        {
          fromDate: date.startDate,
          toDate: date.endDate,
          employeeID: empId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setCustomerList(response.data.customers);
        console.log(response);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }

  React.useEffect(() => {
    table();

    // axios
    //   .post(`${process.env.REACT_APP_BACKEND_URL}api/auth/getAllCustomers`, {
    //     token,
    //   })
    //   .then((r) => console.log(r));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  const handleCustomerDelete = async (idx) => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      const Token = localStorage.getItem("token");
      if (token) {
        loginHeaders.append("Authorization", `Bearer ${token}`);
      }
      const data = { id: idx };
      const requestOptions = {
        method: "DELETE",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}api/auth/deleteCustomer`,
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      if (actualData.status == 200) {
        table();
        toast.success("Deleted successfully!", {
          position: "top-center",
        });
      }
    } catch (err) {
      console.log(err);
      toast.error("An error occurred. Please try again.", {
        position: "top-center",
      });
    }
  };

  const downloadExcel = () => {
    const ws = XLSX.utils.json_to_sheet(
      customerList.map((customer, idx) => ({
        "Sl No.": idx + 1,
        "Business Name": customer.firmName || "--",
        "Contact Person": customer.contactPerson || "--",
        "Address": customer.address || "--",
        "Mobile Number": customer.mobileNumber || "--",
        "WhatsApp Number": customer.whatsappNumber || "--",
        "Email": customer.email || "--",
        "Industry Type": customer.industryType || "--",
        "Sector Type": customer.typeOfSector || "--",
        "District": customer.district || "--",
        "City": customer.city || "--",
        "Area": customer.area || "--",
        "Offering Type": customer.offeringType || "--",
        "Organization Type": customer.organizationType || "--",
        "License Number": customer.licenseNumber || "--",
        "GST Number": customer.gstNumber || "--",
        "Business Details": customer.businessDetails || "--",
        "Remarks": customer.remark || "--",
        "Role": customer.role || "--",
        "Created Date": customer.createdDate || "--",
        "Updated Date": customer.updatedDate || "--",
      }))
    );
  
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Customer List");
    XLSX.writeFile(wb, "CustomerList.xlsx");
  };
  

  return (
    <div className={classes.container}>
      <DashboardLayout />
      <ToastContainer />
      <div className={classes.contentSection}>
        <div
          className={classes.contentHead}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Typography variant="h3" color={"primary"}>
              Customer By Month
            </Typography>
          </div>
          <div
            onClick={() => {
              localStorage.clear();
              navigate("/");
            }}
            style={{ cursor: "pointer" }}
          >
            <Typography variant="h4" color={"primary"}>
              <FaUserFriends /> Log Out
            </Typography>
          </div>
        </div>
        <Grid container spacing={4} className={classes.contentMain}>
          <Grid item xs={12} sm={12} md={12}>
            <CustomDateRangePicker
              date={date}
              setDate={setDate}
              empId={empId}
              setempId={setempId}
            />
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <div className={classes.contentMain}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <p>Search.....</p>
            <Grid item xs={3}>
              <Button
                variant="contained"
                fullWidth
                startIcon={<FaFileExcel />}
                onClick={downloadExcel}
              >
                Download Excel
              </Button>
            </Grid>
          </div>
          <CustomInputField
            fullWidth
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            className="searchField"
          />
          <CustomTimeTable
            fullWidth
            cols={[
              "Sl No.",
              "Business Name",
              "Contact Person",
              "Mobile Number",
              "Offering Type",
              "Organization Type",
            ]}
            rows={[
              ...customerList
                .filter((l) => {
                  const firmName = l.firmName?.toLowerCase() || "";
                  const con_name = l.contactPerson?.toLowerCase() || "";
                  const mobileNumber =
                    (l.mobileNumber ?? "").toString().toLowerCase() || "";
                  const offeringType = l.offeringType?.toLowerCase() || "";
                  const organizationType =
                    l.organizationType?.toLowerCase() || "";

                  const searchLower = (searchText || "").toLowerCase();

                  return (
                    firmName.includes(searchLower) ||
                    con_name.includes(searchLower) ||
                    mobileNumber.includes(searchLower) ||
                    offeringType.includes(searchLower) ||
                    organizationType.includes(searchLower) ||
                    searchLower === undefined
                  );
                })
                ?.map((vs, idx) => [
                  idx + 1,
                  vs.firmName == null || vs.firmName == "" ? "--" : vs.firmName,
                  vs.contactPerson == null || vs.contactPerson == ""
                    ? "--"
                    : vs.contactPerson,
                  vs.mobileNumber == null || vs.mobileNumber == ""
                    ? "--"
                    : vs.mobileNumber,
                  vs.offeringType == null || vs.offeringType == ""
                    ? "--"
                    : vs.offeringType,
                  vs.organizationType == null || vs.organizationType == ""
                    ? "--"
                    : vs.organizationType,
                ]),
            ]}
          />
        </div>
      </div>
    </div>
  );
}

import React, { useState } from "react";
import {
  Avatar,
  Divider,
  Typography,
  Grid,
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  IconButton,
} from "@mui/material";
import { FaEdit, FaTimes, FaUserFriends, FaEllipsisV } from "react-icons/fa";
import { useNavigate } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomInputField from "../../components/CustomInputField/Index";
import DashboardLayout from "../../layouts";
import countryListStyles from "../../styles/pages/CountryList/index.style";
import CustomTimeTable from "../../components/CustomTimeTable/Index";
import CustomButton from "../../components/CustomButton/Index";
import axios from "axios";
import CustomDateRangePicker from "../../components/CustomDateRange";
import * as XLSX from "xlsx";
import { FaFileExcel } from "react-icons/fa";

export default function Follow_upTomorrow() {
  const classes = countryListStyles();
  const token = localStorage.getItem("token");

  const [searchText, setSearchText] = React.useState("");
  const [newLeadList, setNewLeadList] = useState([]);
  const [empId, setempId] = useState(null);
  const [date, setDate] = React.useState({
    startDate: null,
    endDate: null,
  });
  const navigate = useNavigate();
  console.log(date);

  function table() {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}api/auth/getDashboardDetails`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        // setNewLeadList(response.data.leadDetails);
        setNewLeadList(response.data.tomorrowFollowupReminderDetails);
        console.log(response.data.tomorrowFollowupReminderDetails);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }

  React.useEffect(() => {
    table();
  }, []);

  const handleCustomerDelete = async (idx) => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      const Token = localStorage.getItem("token");
      if (token) {
        loginHeaders.append("Authorization", `Bearer ${token}`);
      }
      const data = { id: idx };
      const requestOptions = {
        method: "DELETE",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}api/auth/deleteCustomer`,
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      if (actualData.status == 200) {
        table();
        toast.success("Deleted successfully!", {
          position: "top-center",
        });
      }
    } catch (err) {
      console.log(err);
      toast.error("An error occurred. Please try again.", {
        position: "top-center",
      });
    }
  };

  const downloadExcel = () => {
    const ws = XLSX.utils.json_to_sheet(
      newLeadList.map((lead, idx) => ({
        "Sl No.": idx + 1,
        "Business Name": lead.businessName || "--",
        "Contact Person": lead.contactPerson || "--",
        "Phone Number": lead.mobileNumber || "--",
        Email: lead.email || "--",
        Remarks:
          lead.remarkDetails
            ?.map((remarkGroup) =>
              remarkGroup
                ?.map(
                  (remark) =>
                    `Date/Time: ${remark.dateAndTime || "--"}\nName: ${
                      remark.memberName || "--"
                    }\nRemark: ${remark.remark || "--"}`
                )
                .join("\n\n")
            )
            .join("\n\n") || "--",
      }))
    );
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Leads List");
    XLSX.writeFile(wb, "LeadList.xlsx");
  };

  return (
    <div className={classes.container}>
      <DashboardLayout />
      <ToastContainer />
      <div className={classes.contentSection}>
        <div
          className={classes.contentHead}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Typography variant="h3" color={"primary"}>
              Follow Up Tomorrow
            </Typography>
          </div>
          <div
            onClick={() => {
              localStorage.clear();
              navigate("/");
            }}
            style={{ cursor: "pointer" }}
          >
            <Typography variant="h4" color={"primary"}>
              <FaUserFriends /> Log Out
            </Typography>
          </div>
        </div>

        <div className={classes.contentMain}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <p>Search.....</p>
          </div>
          <CustomInputField
            fullWidth
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            className="searchField"
          />
          <CustomTimeTable
            fullWidth
            cols={[
              "Sl No.",
              "Business Name",
              "Contact Person",
              "Phone Number",
              "Created By",
              "Remarks",
              "Action",
            ]}
            rows={[
              ...newLeadList
                ?.filter((l) => {
                  const bus_name =
                    l.leadDetails.businessName?.toLowerCase() || "";
                  const con_name =
                    l.leadDetails.contactPerson?.toLowerCase() || "";
                  const mobile = l.leadDetails.mobileNumber?.toString() || "";

                  // Concatenate all member names into a single string
                  const memberNames =
                    l.membersID
                      ?.map((member) => member.name.toLowerCase())
                      .join(", ") || "";

                  const searchLower = (searchText || "").toLowerCase();

                  return (
                    bus_name.includes(searchLower) ||
                    con_name.includes(searchLower) ||
                    mobile.includes(searchLower) ||
                    memberNames.includes(searchLower) // Filter based on member names
                  );
                })
                ?.map((vs, idx) => [
                  idx + 1,
                  vs.leadDetails.businessName || "--",
                  vs.leadDetails.contactPerson || "--",
                  vs.leadDetails.mobileNumber || "--",
                  vs.membersID?.map((member) => member.name).join(", ") || "--", // Display member names
                  <div>
                    {vs.leadDetails.remarkDetails?.map((remark, remarkIdx) => (
                      <div key={remarkIdx} style={{ marginTop: "8px" }}>
                        <div>Date/Time: {remark.dateAndTime || "--"}</div>
                        <div>Name: {remark.memberName || "--"}</div>
                        <div>Remark: {remark.remark || "--"}</div>
                      </div>
                    ))}
                  </div>,
                  <span className={classes.actionBtn}>
                    <CustomButton
                      variant="contained"
                      onClick={() => {
                        navigate("/Lead_details", {
                          state: { updateId: vs.leadID.id },
                        });
                      }}
                      text="View"
                    />
                  </span>,
                ]),
            ]}
          />
        </div>
      </div>
    </div>
  );
}

import { Button, Card, Grid } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import * as React from "react";
import CustomDatePicker from "../CustomDateField/Index";
import { format } from "date-fns";
import CustomInputAndSelectField from "../CustomInputAndSelectField/Index";
import axios from "axios";

const RootStyle = styled(Card)(({ theme }) => ({
  display: "flex",
  boxShadow: "none",
  textAlign: "center",
  margin: theme.spacing(3, 0),
  padding: theme.spacing(5, 0),
  color: theme.palette.primary.darker,
}));

const IconWrapperStyle = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  borderRadius: "50%",
  alignItems: "right",
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: "center",
  marginBottom: theme.spacing(3),
  color: theme.palette.primary.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(
    theme.palette.primary.dark,
    0
  )} 0%, ${alpha(theme.palette.primary.dark, 0.24)} 100%)`,
}));

export default function CustomDateRangePicker({
  date,
  setDate,
  empId,
  setempId,
  custId,
  setcustId,
}) {
  const [startDate, setStartDate] = React.useState(null);
  const token = localStorage.getItem("token");
  const [endDate, setEndDate] = React.useState(null);
  const [mainList, setmainList] = React.useState([]);
  const [customerList, setCustomerList] = React.useState([]);
  const [emp, setEmp] = React.useState({
    id: null,
    name: "",
  });
  const [cust, setCust] = React.useState({
    id: null,
    name: "",
  });
  const emptable = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}api/auth/getEmployeeDetails`,
        requestOptions
      );
      const actualData = await res.json();
      setmainList(actualData.employees);
    } catch (err) {
      console.log(err);
    }
  };
  function table() {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}api/auth/getAllCustomers`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        // Handle the response
        setCustomerList(response.data.customers);
      })
      .catch((error) => {
        // Handle errors
        console.error("Error fetching data:", error);
      });
  }

  React.useEffect(() => {
    emptable();
    table();
  }, []);

  const handleFetchData = () => {
    if (empId !== undefined || setempId !== undefined) {
      setempId(emp.id);
    }

    setDate({
      startDate: format(startDate, "MM-dd-yyyy"),
      endDate: format(endDate, "MM-dd-yyyy"),
    });
    if (custId !== undefined || setcustId !== undefined) {
      setcustId(cust.id);
    }
  };

  return (
    <RootStyle>
      <b style={{ margin: "auto 10px" }}>Select Date Range:</b>
      <div
        style={{ display: "flex", gap: 10, justifyContent: "space-between" }}
      >
        <CustomDatePicker
          value={startDate}
          inputFormat="MM-dd-yyyy"
          views={["year", "month", "day"]}
          label="Start Date"
          changeCallBack={(e, v) => {
            setStartDate(new Date(v));
          }}
        />
        <CustomDatePicker
          label="End Date"
          inputFormat="MM-dd-yyyy"
          views={["year", "month", "day"]}
          changeCallBack={(e, v) => {
            setEndDate(new Date(v));
          }}
          minDate={startDate ? startDate : new Date()}
          value={endDate}
        />
        {/* Conditionally render the employee dropdown if empId or setempId is passed */}
        {(empId !== undefined || setempId !== undefined) && (
          <Grid item xs={5}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "-10px",
              }}
            >
              <p
                style={{
                  textAlign: "left",
                  fontWeight: "normal",
                  fontSize: "15px",
                }}
              >
                Employee
              </p>
              <CustomInputAndSelectField
                options={mainList?.map(
                  (item) => item.personalDetails.employeeName
                )}
                value={emp.name}
                changeCallBack={(e, v) => {
                  const selected = mainList.find(
                    (item) => item.personalDetails.employeeName === v
                  );

                  setEmp((prevState) => ({
                    ...prevState,
                    name: v,
                    id: selected ? selected._id : null,
                  }));
                }}
                fullWidth
                sx={{ marginTop: "-20px" }}
              />
            </div>
          </Grid>
        )}
        {(custId !== undefined || setcustId !== undefined) && (
          <Grid item xs={5}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "-10px",
              }}
            >
              <p
                style={{
                  textAlign: "left",
                  fontWeight: "normal",
                  fontSize: "15px",
                }}
              >
                Customer
              </p>
              <CustomInputAndSelectField
                options={customerList?.map((item) => item.contactPerson)}
                value={cust.name}
                changeCallBack={(e, v) => {
                  const selected = customerList.find(
                    (item) => item.contactPerson === v
                  );

                  setCust((prevState) => ({
                    ...prevState,
                    name: v,
                    id: selected ? selected._id : null,
                  }));
                }}
                fullWidth
                sx={{ marginTop: "-20px" }}
              />
            </div>
          </Grid>
        )}
        <Button
          onClick={handleFetchData}
          style={{ marginLeft: "20px" }}
          color="primary"
          variant="contained"
        >
          Fetch Data
        </Button>
      </div>
    </RootStyle>
  );
}

import { Divider, Grid, Paper, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { AiFillHome } from "react-icons/ai";

import { Navigate, useNavigate } from "react-router-dom";
import DashboardLayout from "../../layouts";
import useStyles from "../../styles/pages/Dashboard/index.style";

import { MdLogout } from "react-icons/md";

import LeftSide from "./LeftSide";
import { ToastContainer, toast } from "react-toastify";
import { FaEdit, FaTimes } from "react-icons/fa";
import CustomButton from "../../components/CustomButton/Index";
import CountryTable from "../../components/CountryTable/Index";
import CustomInputField from "../../components/CustomInputField/Index";
import CustomInputAndSelectField from "../../components/CustomInputAndSelectField/Index";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import ConfirmationDialog from "../../components/ConfirmationDialog/Index";

const OfficeShift = () => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    startTime: "",
    endTime: "",

    isUpdate: false,
  });
  const [shiftList, setShiftList] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState(null);
  // const convertToAMPM = (time) => {
  //   let [hours, minutes] = time.split(':');
  //   const ampm = hours >= 12 ? 'PM' : 'AM';
  //   hours = hours % 12 || 12;
  //   hours = hours < 10 ? `0${hours}` : hours;    console.log(`${hours}:${minutes} ${ampm}`);
  //   return `${hours}:${minutes} ${ampm}`;
  // };

  // const convertTo24Hour = (time) => {
  //   let [hours, minutes] = time.split(':');
  //   const ampm = time.includes('PM') ? 'PM' : 'AM';
  //   if (ampm === 'PM' && hours !== '12') {
  //     hours = parseInt(hours, 10) + 12;
  //   }
  //   if (ampm === 'AM' && hours === '12') {
  //     hours = '00';
  //   }
  //   console.log(`${hours}:${minutes}`);
  //   return `${hours}:${minutes}`;
  // };

  function formatTimeTo12Hour(time24) {
    let [hour, minute] = time24.split(":");
    hour = parseInt(hour, 10);
    const period = hour >= 12 ? "PM" : "AM";
    hour = hour % 12 || 12; // Convert hour to 12-hour format
    return `${hour}:${minute} ${period}`;
  }

  const [startTime, setstartTime] = useState("");
  const [endTime, setendTime] = useState("");

  console.log(state);

  function formatTimeTo24Hour(time12) {
    const [time, period] = time12.split(" ");
    let [hour, minute] = time.split(":");
    hour = parseInt(hour, 10);
    if (period === "PM" && hour !== 12) {
      hour += 12;
    } else if (period === "AM" && hour === 12) {
      hour = 0;
    }
    return `${hour.toString().padStart(2, "0")}:${minute}`;
  }
  const handleUpdatestartTimeFromApi = (time) => {
    const formattedTime = formatTimeTo24Hour(time);
    setstartTime(formattedTime);
  };

  const handleUpdateendTimeFromApi = (time) => {
    const formattedTime = formatTimeTo24Hour(time);
    setendTime(formattedTime);
  };

  const table = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}api/auth/getWorkshiftDetails`,
        requestOptions
      );
      const actualData = await res.json();

      console.log(actualData);
      setShiftList(actualData.workshifts);
    } catch (err) {
      console.log(err);
    }
  };
  React.useEffect(() => {
    table();
  }, []);

  const handleCreateShift = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }
      const data = {
        // shiftName: state.shiftName,
        workShift: {
          // startTime: convertTo24Hour(state.startTime),
          // endTime: convertTo24Hour(state.endTime),
          startTime: state.startTime,
          endTime: state.endTime,
          // startTime: convertTo24Hour(state.startTime),
          // endTime: convertTo24Hour(state.endTime),
          startTime: state.startTime,
          endTime: state.endTime,
        },
      };
      const requestOptions = {
        method: "POST",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}api/auth/createWorkshift`,
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      // setVisaList(actualData.Country);
      if (actualData.status == 200) {
        toast.success("Created Sucessfully", {
          position: "top-center",
        });
        table();
        setState({
          // shiftName: "",
          startTime: "",
          endTime: "",

          isUpdate: false,
        });
        setstartTime("");
        setendTime("");

        setstartTime("");
        setendTime("");

        localStorage.removeItem("fee_icon");
      }
    } catch (err) {
      console.log(err);
    }
  };

  //UPDATE DEPARTMENT
  const handleUpdateShift = async (id) => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }
      const data = {
        id: id,
        // shiftName: state.shiftName,
        workShift: {
          // startTime: convertTo24Hour(state.startTime),
          // endTime: convertTo24Hour(state.endTime),
          startTime: state.startTime,
          endTime: state.endTime,
          // startTime: convertTo24Hour(state.startTime),
          // endTime: convertTo24Hour(state.endTime),
          startTime: state.startTime,
          endTime: state.endTime,
        },
      };
      const requestOptions = {
        method: "PUT",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(
        ` ${process.env.REACT_APP_API_URL}api/auth/updateWorkshift`,
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      // setVisaList(actualData.Country);
      if (actualData.status == 200) {
        toast.success("Updated Sucessfully", {
          position: "top-center",
        });
        table();
        localStorage.removeItem("updateId");
        setState({
          // shiftName: "",
          startTime: "",
          endTime: "",

          isUpdate: false,
        });
        setstartTime("");
        setendTime("");

        localStorage.removeItem("fee_icon");
        // Navigate("/Department");
      }
    } catch (err) {
      console.log(err);
    }
    console.log(id);
  };

  //DELETE DEPARTMENT
  const handleShiftDelete = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }
      const data = { id: deleteId };
      const requestOptions = {
        method: "DELETE",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}api/auth/deleteWorkshift`,
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      // setVisaList(actualData.Country);
      if (actualData.status == 200) {
        toast.success("Deleted Sucessfully", {
          position: "top-center",
        });
        table();
      } else {
        toast.error(actualData.message, {
          position: "top-center",
        });
      }
      setOpen(false);
    } catch (err) {
      console.log(err);
    }
  };
  const handleOpenDialog = (id) => {
    setDeleteId(id);
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
    setDeleteId(null);
  };
  return (
    <div className={classes.container}>
      <DashboardLayout />
      <div className={classes.contentSection}>
        <div
          className={classes.contentHead}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            {" "}
            <Typography variant="h3" color={"primary"}>
              <AiFillHome /> HRM Management
            </Typography>
          </div>

          <div
            onClick={() => {
              localStorage.clear();
              Navigate("/");
            }}
            style={{ cursor: "pointer" }}
          >
            {" "}
            <Typography
              variant="h4"
              color="primary"
              style={{ display: "flex", alignItems: "center" }}
            >
              Log out
              <MdLogout size={30} style={{ marginLeft: "8px" }} />
            </Typography>
          </div>
        </div>

        <Divider className={classes.divider} />

        <div style={{ display: "flex", gap: "20px" }}>
          <LeftSide />

          <div>
            <div
              className={classes.contentMain}
              style={{
                width: "62vw",
              }}
            >
              <Paper className={classes.paper}>
                <Grid
                  container
                  spacing={3}
                  className="content"
                  alignItems={"end"}
                >
                  {/* <Grid item xs={6}>
                    <p
                      style={{
                        textAlign: "left",
                        fontWeight: "normal",
                        fontSize: "15px",
                      }}
                    >
                      Start Time
                    </p>
                    <TextField
                      fullWidth
                      type="time"
                      value={state.startTime}
                      onChange={(e) =>
                        setState({
                          ...state,
                          startTime: e.target.value,
                        })
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        step: 300, // 5 min increments
                      }}
                    />
                    {state.startTime && (
                      <p
                        style={{
                          textAlign: "left",
                          fontWeight: "normal",
                          fontSize: "15px",
                          marginTop: "10px",
                        }}
                      >
                        {`Selected Time: ${formatTimeWithAmPm(
                          state.startTime
                        )}`}
                      </p>
                    )}
                  </Grid> */}

                  <Grid item xs={6}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <p
                        style={{
                          textAlign: "left",
                          fontWeight: "normal",
                          fontSize: "15px",
                        }}
                      >
                        Start Time
                      </p>
                      {/* <CustomInputField
                        // // label="Department name"
                        // fullWidth
                        // value={state.startTime.slice(0,5)}
                        // type="time"
                        // onChange={(e) =>
                        //   setState({
                        //     ...state,
                        //     startTime: convertToAMPM(e.target.value),
                        //   })
                        // }
                      /> */}

                      <CustomInputField
                        type="time"
                        // label="Start Time"
                        fullWidth
                        value={startTime}
                        onChange={(e) => {
                          const selectedTime = e.target.value; // Get the selected time from input

                          // Convert selectedTime from 24-hour to 12-hour format with AM/PM
                          const formattedTime =
                            formatTimeTo12Hour(selectedTime);

                          setstartTime(e.target.value);

                          // Update the state with the formatted time
                          setState({
                            ...state,
                            startTime: formattedTime,
                          });
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <p
                        style={{
                          textAlign: "left",
                          fontWeight: "normal",
                          fontSize: "15px",
                        }}
                      >
                        End Time
                      </p>
                      {/* <CustomInputField
                        // label="Department name"
                        fullWidth
                        value={state.endTime.slice(0,5)}
                        type="time"
                        onChange={(e) =>
                          setState({
                            ...state,
                            endTime: convertToAMPM(e.target.value),
                          })
                        }
                      /> */}

                      <CustomInputField
                        type="time"
                        // label="End Time"
                        fullWidth
                        value={endTime}
                        onChange={(e) => {
                          const selectedTime = e.target.value; // Get the selected time from input

                          // Convert selectedTime from 24-hour to 12-hour format with AM/PM
                          const formattedTime =
                            formatTimeTo12Hour(selectedTime);

                          setendTime(e.target.value);
                          // Update the state with the formatted time
                          setState({
                            ...state,
                            endTime: formattedTime,
                          });
                        }}
                      />
                    </div>
                  </Grid>

                  <Grid item xs={2} alignItems="end">
                    {state.isUpdate ? (
                      <CustomButton
                        text={"Update"}
                        variant="contained"
                        color="warning"
                        fullWidth
                        onClick={(e) => {
                          if (
                            // !!state.shiftName,
                            (!!state.startTime, !!state.endTime)
                          ) {
                            handleUpdateShift(state.id);
                          } else {
                            toast.error("Fill all the information", {
                              position: "top-center",
                            });
                          }
                        }}
                        // onClick={(e) => {
                        //   handleUpdateVisaCategory(state.id);
                        // }}
                      />
                    ) : (
                      <CustomButton
                        text={"create"}
                        variant="contained"
                        fullWidth
                        onClick={(e) => {
                          if (
                            // !!state.shiftName,
                            (!!state.startTime, !!state.endTime)

                            // !!state.fee_descriptions.getCurrentContent().hasText()
                          ) {
                            handleCreateShift();
                          } else {
                            toast.error("Fill all the information", {
                              position: "top-center",
                            });
                          }
                        }}
                        //  onClick={(e) => {
                        //   handleSaveVisaCategory();
                        // }}
                      />
                    )}
                  </Grid>
                </Grid>
              </Paper>
              {/* <p className={classes.title}>Fee types List</p> */}
              <Paper className={classes.paper}>
                <label
                  style={{
                    fontSize: "12px",
                    marginTop: "10px",
                    marginBottom: "-11px",
                    textAlign: "left",
                    display: "block",
                    paddingTop: "24px",
                  }}
                >
                  Search...
                </label>
                <CustomInputField
                  fullWidth
                  value={state.searchText}
                  onChange={(e) =>
                    setState({
                      ...state,
                      searchText: e.target.value,
                    })
                  }
                  className="searchField"
                />
                <div style={{ marginTop: "20px" }}>
                  {" "}
                  {/* Add margin here */}
                  <CountryTable
                    fullWidth
                    cols={[
                      "Sl No.",
                      "Shift Name",
                      "Work Shift Timing",
                      "Action",
                    ]}
                    rows={[
                      ...shiftList
                        .filter((l) => {
                          const shiftName = l.shiftName?.toLowerCase() || "";
                          const workShift = l.workShift?.toLowerCase() || "";

                          const searchLower = (
                            state.searchText || ""
                          ).toLowerCase();

                          return (
                            shiftName.includes(searchLower) ||
                            workShift.includes(searchLower) ||
                            searchLower === undefined
                          );
                        })
                        ?.map((vs, idx) => [
                          idx + 1,
                          vs.shiftName,
                          vs.workShift,

                          <div
                            className={classes.actionBtn}
                            style={{
                              flexDirection: "row",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <CustomButton
                              variant="contained"
                              color="error"
                              onClick={(e) => handleShiftDelete(vs._id)}
                              icon={<FaTimes />}
                            />
                            <CustomButton
                              variant="contained"
                              color="warning"
                              onClick={(e) => {
                                setState({
                                  // shiftName: vs.shiftName,
                                  startTime: vs.workShift.split(" - ")[0],
                                  // endTime: convertToAMPM(
                                  //   vs.workShift.split("-")[1]
                                  // ),
                                  endTime: vs.workShift.split(" - ")[1],
                                  isUpdate: true,
                                  id: vs._id,
                                  // workShift: vs.workShift
                                });
                                console.log(vs.workShift.split(" - ")[0]);
                                console.log(vs.workShift.split(" - ")[1]);
                                handleUpdatestartTimeFromApi(
                                  vs.workShift.split(" - ")[0]
                                );
                                handleUpdateendTimeFromApi(
                                  vs.workShift.split(" - ")[1]
                                );
                              }}
                              icon={<FaEdit />}
                            />
                          </div>,
                        ]),
                    ]}
                  />
                </div>
                <ToastContainer />
              </Paper>
            </div>
          </div>
        </div>
      </div>
      <ConfirmationDialog
        open={open}
        onClose={handleCloseDialog}
        onConfirm={handleShiftDelete}
      />
    </div>
  );
};

export default OfficeShift;

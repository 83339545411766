import React, { useState } from "react";
import {
  Avatar,
  Divider,
  Typography,
  Grid,
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  IconButton,
} from "@mui/material";
import { FaEdit, FaTimes, FaUserFriends, FaEllipsisV } from "react-icons/fa";
import { useNavigate } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomInputField from "../../components/CustomInputField/Index";
import DashboardLayout from "../../layouts";
import countryListStyles from "../../styles/pages/CountryList/index.style";
import CustomTimeTable from "../../components/CustomTimeTable/Index";
import CustomButton from "../../components/CustomButton/Index";
import axios from "axios";
import CustomDateRangePicker from "../../components/CustomDateRange";
import * as XLSX from "xlsx";
import { FaFileExcel } from "react-icons/fa";

export default function Service_By_Month() {
  const classes = countryListStyles();
  const token = localStorage.getItem("token");

  const [searchText, setSearchText] = React.useState("");
  const [newLeadList, setNewLeadList] = useState([]);
  const [empId, setempId] = useState(null);
  const [custId, setcustId] = useState(null);
  const [date, setDate] = React.useState({
    startDate: null,
    endDate: null,
  });
  const navigate = useNavigate();
  console.log(date);

  function table() {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}api/auth/getAllServicesbydate`,
        {
          fromDate: date.startDate,
          toDate: date.endDate,
          employeeID: empId,
          customerID: custId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setNewLeadList(response.data.data);
        console.log(response);
      })
      .catch((error) => {
        setNewLeadList([]);
        console.error("Error fetching data:", error);
      });
  }

  React.useEffect(() => {
    table();

    // axios
    //   .post(`${process.env.REACT_APP_BACKEND_URL}api/auth/getAllCustomers`, {
    //     token,
    //   })
    //   .then((r) => console.log(r));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, empId, custId]);

  const handleCustomerDelete = async (idx) => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      const Token = localStorage.getItem("token");
      if (token) {
        loginHeaders.append("Authorization", `Bearer ${token}`);
      }
      const data = { id: idx };
      const requestOptions = {
        method: "DELETE",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}api/auth/deleteCustomer`,
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      if (actualData.status == 200) {
        table();
        toast.success("Deleted successfully!", {
          position: "top-center",
        });
      }
    } catch (err) {
      console.log(err);
      toast.error("An error occurred. Please try again.", {
        position: "top-center",
      });
    }
  };

  const downloadExcel = () => {
    const ws = XLSX.utils.json_to_sheet(
      newLeadList.map((lead, idx) => ({
        "Sl No.": idx + 1,
        "Service Name": lead.serviceName?.nameOfService || "--",
        "Customer Name": lead.customerID?.contactPerson || "--",
        "Assigned To": lead.transferTOID?.employeeName || "--",
        "Service Status": lead.serviceStatus || "--",
        "Remark": lead.remark || "--",
        "Created Date": lead.createdDate?.slice(0, 10) || "--",
        "Due Date": lead.dueDate?.slice(0, 10) || "--",
        "Resolved Date": lead.resolvedDate ? lead.resolvedDate.slice(0, 10) : "--",
        "Updated Date": lead.updatedDate?.slice(0, 10) || "--",
        "Creator Name": lead.serviceCreatorID?.creatorName || "--",
        "Role": lead.role || "--",
      }))
    );
  
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Service List");
    XLSX.writeFile(wb, "ServiceList.xlsx");
  };
  

  return (
    <div className={classes.container}>
      <DashboardLayout />
      <ToastContainer />
      <div className={classes.contentSection}>
        <div
          className={classes.contentHead}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Typography variant="h3" color={"primary"}>
              Service By Month
            </Typography>
          </div>
          <div
            onClick={() => {
              localStorage.clear();
              navigate("/");
            }}
            style={{ cursor: "pointer" }}
          >
            <Typography variant="h4" color={"primary"}>
              <FaUserFriends /> Log Out
            </Typography>
          </div>
        </div>
        <Grid container spacing={4} className={classes.contentMain}>
          <Grid item xs={12} sm={12} md={12}>
            <CustomDateRangePicker
              date={date}
              setDate={setDate}
              empId={empId}
              setempId={setempId}
              custId={custId}
              setcustId={setcustId}
            />
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <div className={classes.contentMain}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <p>Search.....</p>
            <Grid item xs={3}>
              <Button
                variant="contained"
                fullWidth
                startIcon={<FaFileExcel />}
                onClick={downloadExcel}
              >
                Download Excel
              </Button>
            </Grid>
          </div>
          <CustomInputField
            fullWidth
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            className="searchField"
          />
          <CustomTimeTable
            fullWidth
            cols={[
              "Sl No.",
              "Service Name",
              "Customer Name",
              "Remark",
              "Assigned To",
              "Created Date",
              "Resolved Date",

              "Status",
            ]}
            rows={[
              ...newLeadList
                // .filter((l) => {
                //   const bus_name = l.businessName?.toLowerCase() || "";
                //   const con_name = l.contactPerson?.toLowerCase() || "";
                //   const mobile =
                //     (l.mobileNumber ?? "").toString().toLowerCase() || "";
                //   const creatorName = l.creatorName?.toLowerCase() || "";

                //   const searchLower = (searchText || "").toLowerCase();

                //   return (
                //     bus_name.includes(searchLower) ||
                //     con_name.includes(searchLower) ||
                //     mobile.includes(searchLower) ||
                //     creatorName.includes(searchLower) ||
                //     searchLower === undefined
                //   );
                // })
                ?.map((vs, idx) => [
                  idx + 1,
                  vs.serviceName.nameOfService || "--",
                  vs.customerID.contactPerson || "--",
                  vs.remark || "--",
                  vs.transferTOID.employeeName || "--",
                  vs.createdDate.slice(0, 10) || "--",
                  vs.resolvedDate == null ? "--" : vs.resolvedDate,

                  vs.serviceStatus || "",
                ]),
            ]}
          />
        </div>
      </div>
    </div>
  );
}

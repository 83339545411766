import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Select,
  MenuItem as MuiMenuItem,
  Button,
  Grid,
} from "@mui/material";
import CustomInputAndSelectField from "../CustomInputAndSelectField/Index";

export default function DemoSchedulePopup({
  open,
  handleClose,
  handleSave,
  selectedEmployee,
}) {
  const [demoScheduleData, setDemoScheduleData] = useState({
    employee: "",
    date: "",
    time: "",
  });
  const token = localStorage.getItem("token");
  const handleDataChange = (field, value) => {
    setDemoScheduleData({ ...demoScheduleData, [field]: value });
  };
  console.log(demoScheduleData);
  const handleSaveClick = () => {
    handleSave(demoScheduleData);
    setDemoScheduleData({ employee: "", date: "", time: "" }); // Reset form
  };
  console.log(selectedEmployee);
  const [mainlist, setMainList] = useState([]);
  const [selectedSector, setSelectedSector] = useState("");
  const [selectedSectorId, setSelectedSectorId] = useState("");
  const table = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}api/auth/getEmployeeDetails`,
        requestOptions
      );
      const actualData = await res.json();
      setMainList(actualData.employees);
    } catch (err) {
      console.log(err);
    }
  };

  const handleCreateDpt = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }
      const data = {
        leadID: selectedEmployee._id,
        memberID: demoScheduleData.employee,
        dateAndTime: `${demoScheduleData.date} ${demoScheduleData.time}`,
      };
      const requestOptions = {
        method: "POST",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}api/auth/createDemoSchedule`,
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      if (actualData.status === 200) {
        setDemoScheduleData({
          employee: "",
          date: "",
          time: "",
        });
        setSelectedSector("");
        handleSave();
      }
    } catch (err) {
      console.log(err);
    }
  };
  function convertTo12HourFormat(time) {
    // Split the time into hours and minutes
    let [hours, minutes] = time.split(":");
    hours = parseInt(hours, 10);

    // Determine AM/PM
    const ampm = hours >= 12 ? "PM" : "AM";

    // Convert hours from 24-hour format to 12-hour format
    hours = hours % 12 || 12; // Convert '0' hours to '12'

    // Format hours and minutes
    hours = hours.toString().padStart(2, "0");
    minutes = minutes.toString().padStart(2, "0");

    // Return the formatted time
    return `${hours}:${minutes} ${ampm}`;
  }
  //  const handleUpdateDpt = async () => {
  //    try {
  //      const loginHeaders = new Headers();
  //      loginHeaders.append("Content-Type", "application/json");

  //      const authToken = localStorage.getItem("token");
  //      if (authToken) {
  //        loginHeaders.append("Authorization", `Bearer ${authToken}`);
  //      }
  //      const data = {
  //        id: transferId,
  //        leadID: selectedEmployee._id,
  //        memberID: selectedSectorId,
  //      };
  //      const requestOptions = {
  //        method: "PUT",
  //        headers: loginHeaders,
  //        body: JSON.stringify(data),
  //      };
  //      const res = await fetch(
  //        `${process.env.REACT_APP_API_URL}api/auth/updateLeadTransfer`,
  //        requestOptions
  //      );
  //      const actualData = await res.json();
  //      console.log(actualData);
  //      if (actualData.status === 200) {
  //        handleSave();
  //      }
  //    } catch (err) {
  //      console.log(err);
  //    }
  //  };
  console.log(demoScheduleData);
  useEffect(() => {
    table();

    // Function to fetch sector types (adjust the API as necessary)
    //  if (selectedEmployee && selectedEmployee.leadTransferDetails.length > 0) {
    //    setSelectedSector(
    //      selectedEmployee.leadTransferDetails[0].employeeName || ""
    //    );
    //    setSelectedSectorId(
    //      selectedEmployee.leadTransferDetails[0].memberID || ""
    //    );
    //    setTransferId(selectedEmployee.leadTransferDetails[0].transferID || null);
    //  }
  }, [token]);
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="demo-schedule-dialog-title"
    >
      <DialogTitle id="demo-schedule-dialog-title">Schedule Demo</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please fill in the details to schedule the demo.
        </DialogContentText>
        <Grid item xs={5}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <p
              style={{
                textAlign: "left",
                fontWeight: "normal",
                fontSize: "15px",
                marginTop: "20px",
              }}
            >
              Employee
            </p>
            <CustomInputAndSelectField
              options={mainlist?.map(
                (item) => item.personalDetails.employeeName
              )}
              value={selectedSector}
              changeCallBack={(e, v) => {
                const selectedEntryType = mainlist.find(
                  (item) => item.personalDetails.employeeName === v
                );
                setSelectedSector(v);
                // setSelectedSectorId(
                //   selectedEntryType ? selectedEntryType._id : ""
                // );
                setDemoScheduleData({
                  ...demoScheduleData,
                  employee: selectedEntryType ? selectedEntryType._id : "",
                });
              }}
              fullWidth
            />
          </div>
        </Grid>
        <Grid item xs={5}>
          <p style={{ marginTop: "20px" }}>Date</p>
          <input
            required=""
            style={{
              padding: "10px",
              // borderColor: "rgba(148,158,171,0.32)",
              boxShadow: "1px 3px 10px rgba(0,0,0,0.17)",
              width: "100%",
              borderRadius: "10px",
              border: "none",
              marginTop: "10px",
            }}
            onChange={(e) =>
              setDemoScheduleData({
                ...demoScheduleData,
                date: e.target.value,
              })
            }
            type="date"
            min={new Date().toISOString().split("T")[0]}
          />
        </Grid>
        <Grid item xs={5}>
          <p style={{ marginTop: "20px" }}>Time</p>
          <input
            required=""
            style={{
              padding: "10px",
              // borderColor: "rgba(148,158,171,0.32)",
              boxShadow: "1px 3px 10px rgba(0,0,0,0.17)",
              width: "100%",
              borderRadius: "10px",
              border: "none",
              marginTop: "10px",
            }}
            onChange={(e) =>
              setDemoScheduleData({
                ...demoScheduleData,
                time: convertTo12HourFormat(e.target.value),
              })
            }
            type="time"
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleCreateDpt} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import { Divider, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { AiFillHome } from "react-icons/ai";
import { Navigate, useNavigate } from "react-router-dom";
import DashboardLayout from "../../layouts";
import useStyles from "../../styles/pages/Dashboard/index.style";
import { MdLogout } from "react-icons/md";
import LeftSide from "./LeftSide";
import { ToastContainer, toast } from "react-toastify";
import { FaEdit, FaTimes, FaTrash } from "react-icons/fa";
import CustomButton from "../../components/CustomButton/Index";
import CountryTable from "../../components/CountryTable/Index";
import CustomInputField from "../../components/CustomInputField/Index";
import CustomInputAndSelectField from "../../components/CustomInputAndSelectField/Index";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import ConfirmationDialog from "../../components/ConfirmationDialog/Index";

const Business_Details = () => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    businessDetailName: "",
    isUpdate: false,
  });
  const [business_details, setbusiness_details] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState(null);
  const table = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}api/auth/getAllBusinessDetails`,
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      setbusiness_details(actualData.businessDetails);
    } catch (err) {
      console.log(err);
    }
  };
  React.useEffect(() => {
    table();
  }, []);

  const handleCreateDpt = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }
      const data = {
        businessDetailName: state.businessDetailName,
      };
      const requestOptions = {
        method: "POST",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}api/auth/createBusinessDetail`,
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      if (actualData.status === 200) {
        toast.success("Created Sucessfully", {
          position: "top-center",
        });
        table();
        setState({
          businessDetailName: "",
          isUpdate: false,
        });
      } else {
        toast.error(actualData.message, {
          position: "top-center",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  //UPDATE business details
  const handleUpdateDpt = async (id) => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }
      const data = {
        id: id,
        businessDetailName: state.businessDetailName,
      };
      const requestOptions = {
        method: "PUT",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}api/auth/updateBusinessDetail`,
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      if (actualData.status === 200) {
        toast.success("Updated Sucessfully", {
          position: "top-center",
        });
        table();
        setState({
          businessDetailName: "",
          isUpdate: false,
        });
      } else {
        toast.error(actualData.message, {
          position: "top-center",
        });
      }
    } catch (err) {
      console.log(err);
    }
    console.log(id);
  };

  //DELETE business details
  const handleDptDelete = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }
      const data = { id: deleteId };
      const requestOptions = {
        method: "DELETE",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}api/auth/deleteBusinessDetail`,
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      if (actualData.status === 200) {
        setOpen(false);
        toast.success("Deleted Sucessfully", {
          position: "top-center",
        });
        table();
      } else {
        toast.error(actualData.message, {
          position: "top-center",
        });
      }
      setOpen(false);
    } catch (err) {
      console.log(err);
    }
  };
  const handleOpenDialog = (id) => {
    setDeleteId(id);

    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
    setDeleteId(null);
  };
  return (
    <div className={classes.container}>
      <DashboardLayout />
      <div className={classes.contentSection}>
        <div
          className={classes.contentHead}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Typography variant="h3" color={"primary"}>
              <AiFillHome /> Master Data
            </Typography>
          </div>

          <div
            onClick={() => {
              localStorage.clear();
              Navigate("/");
            }}
            style={{ cursor: "pointer" }}
          >
            <Typography
              variant="h4"
              color="primary"
              style={{ display: "flex", alignItems: "center" }}
            >
              Log out
              <MdLogout size={30} style={{ marginLeft: "8px" }} />
            </Typography>
          </div>
        </div>

        <Divider className={classes.divider} />

        <div style={{ display: "flex", gap: "20px" }}>
          <LeftSide />

          <div>
            <div className={classes.contentMain} style={{ width: "62vw" }}>
              <Paper className={classes.paper}>
                <Grid
                  container
                  spacing={3}
                  className="content"
                  alignItems={"end"}
                >
                  <Grid item xs={6}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <p
                        style={{
                          textAlign: "left",
                          fontWeight: "normal",
                          fontSize: "15px",
                        }}
                      >
                        Business Details Name
                      </p>
                      <CustomInputField
                        fullWidth
                        value={state.businessDetailName}
                        onChange={(e) => {
                          const input = e.target.value;
                          // Remove non-alphabetic characters
                          const validInput = input.replace(/[^a-zA-Z\s]/g, "");
                          // Ensure input does not exceed 50 characters
                          const trimmedInput = validInput.slice(0, 50);
                          setState({
                            ...state,
                            businessDetailName: trimmedInput,
                          });
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={2} alignItems="end">
                    {state.isUpdate ? (
                      <CustomButton
                        text={"Update"}
                        variant="contained"
                        color="warning"
                        fullWidth
                        onClick={(e) => {
                          if (!!state.businessDetailName) {
                            handleUpdateDpt(state.id);
                          } else {
                            toast.error("Fill all the information", {
                              position: "top-center",
                            });
                          }
                        }}
                      />
                    ) : (
                      <CustomButton
                        text={"create"}
                        variant="contained"
                        fullWidth
                        onClick={(e) => {
                          if (!!state.businessDetailName) {
                            handleCreateDpt();
                          } else {
                            toast.error("Fill all the information", {
                              position: "top-center",
                            });
                          }
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
              </Paper>

              <Paper className={classes.paper}>
                <label
                  style={{
                    fontSize: "12px",
                    marginTop: "10px",
                    marginBottom: "-11px",
                    textAlign: "left",
                    display: "block",
                    paddingTop: "24px",
                  }}
                >
                  Search...
                </label>
                <CustomInputField
                  fullWidth
                  value={state.searchText}
                  onChange={(e) =>
                    setState({
                      ...state,
                      searchText: e.target.value,
                    })
                  }
                  className="searchField"
                />
                <div style={{ marginTop: "20px" }}>
                  <CountryTable
                    fullWidth
                    cols={["Sl No.", "Business Details", "Action"]}
                    rows={[
                      ...business_details
                        .filter((l) => {
                          const businessDetailName =
                            l.businessDetailName?.toLowerCase() || "";

                          const searchLower = (
                            state.searchText || ""
                          ).toLowerCase();

                          return (
                            businessDetailName.includes(searchLower) ||
                            searchLower === undefined
                          );
                        })
                        ?.map((vs, idx) => [
                          idx + 1,
                          vs.businessDetailName,
                          <div
                            className={classes.actionBtn}
                            style={{
                              flexDirection: "row",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <CustomButton
                              variant="contained"
                              color="error"
                              onClick={(e) => handleOpenDialog(vs._id)}
                              icon={<FaTimes />}
                            />
                            <CustomButton
                              variant="contained"
                              color="warning"
                              onClick={(e) =>
                                setState({
                                  businessDetailName: vs.businessDetailName,
                                  isUpdate: true,
                                  id: vs._id,
                                })
                              }
                              icon={<FaEdit />}
                            />
                          </div>,
                        ]),
                    ]}
                  />
                </div>
                <ToastContainer />
              </Paper>
            </div>
          </div>
        </div>
      </div>
      <ConfirmationDialog
        open={open}
        onClose={handleCloseDialog}
        onConfirm={handleDptDelete}
      />
    </div>
  );
};

export default Business_Details;
